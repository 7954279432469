import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import obj from "../utils/constants";
import helpers from "../utils/helpers";
import AuthContext from "../context/AuthContext";
import { get, isEmpty } from "lodash";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
const BetListLive = () => {
  let { user } = useContext(AuthContext);
  const [unmatched, setUnmatched] = useState("");
  const [getBetType, setBetType] = useState("betfair");
  const [getType, setType] = useState("4");
  const [id, setId] = useState("");
  const [filter, setFilter] = useState({
    type: "4",
    betType: "betfair",
    sortType: "amount",
    sortOrder: "asc",
    last: 25,
    refereshTime: 15,
    status: "active",
  });
  const [check, setCheck] = useState(true);
  const [data, setData] = useState([]);
  const getData = async (obj) => {
    let object = !isEmpty(obj) ? obj : filter;
    console.log(object, "object");
    const { status, data: response_users } = await apiGet(
      apiPath.getBetslive,
      object
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.length > 0) {
          setBetType(object.betType);
          setType(object.type);
          let temp = response_users?.results?.filter((res) => !res.isMatched);
          setUnmatched(temp);
          setData(response_users?.results);
        } else {
          setData([]);
        }
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getData(filter);
    }, 15 * 1000);

    return () => clearInterval(intervalID);
  }, [filter]);

  return (
    <div>
      {" "}
      <div className="user_data_div p-1">
        <section className="main-inner-outer py-3">
          <Container fluid>
            <div className="inner-wrapper">
              <div className="common-container">
                <Form className="p-3" style={{ background: "#f3f4f6" }}>
                  <div className="d-lg-flex p-0 bg-transparent">
                    <div key={`inline-radio`} className="mb-1">
                      <ul className="list-unstyled mb-0 d-flex">
                        {obj.betCheckData.map((type) => {
                          return (
                            <li>
                              <Form.Check
                                inline
                                label={type.value}
                                name={type.label}
                                type="radio"
                                checked={
                                  type.label === filter.type ? true : false
                                }
                                onChange={(e) =>
                                  setFilter({
                                    ...filter,
                                    type: e.target.name,
                                    betType:
                                      type?.value == "Casino"
                                        ? "casino"
                                        : "betfair",
                                  })
                                }
                                id={`inline-radio-1`}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div key={`inline-radio`} className="mb-2">
                      <ul className="list-unstyled mb-0 d-flex">
                        {obj.betCheckDataInner.map((type) => {
                          return (
                            <li>
                              <Form.Check
                                inline
                                label={type.value}
                                name={type.label}
                                type="radio"
                                checked={
                                  type.label === filter.betType ? true : false
                                }
                                onChange={(e) =>
                                  setFilter({
                                    ...filter,
                                    betType: e.target.name,
                                  })
                                }
                                id={`inline-radio-1`}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="bet_outer">
                    <div className="bet-sec p-1">
                      <Form.Label>Order of display:</Form.Label>
                      <Form.Select
                        className="small_select"
                        onChange={(e) =>
                          setFilter({ ...filter, sortType: e.target.value })
                        }
                        value={filter.sortType}
                        aria-label="Default select example"
                      >
                        <option value="amount">Stake</option>
                        <option value="timeInserted">Time</option>
                      </Form.Select>
                    </div>

                    <div className="bet-sec p-1 bet-period">
                      <Form.Label>of</Form.Label>
                      <Form.Select
                        className="small_select"
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            sortOrder: e.target.value,
                          })
                        }
                        value={filter.sortOrder}
                        aria-label="Default select example"
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Decending</option>
                      </Form.Select>
                    </div>

                    {/* <div className="bet-sec p-1 bet-period">
                      <Form.Label>Last</Form.Label>
                      <Form.Select
                        className="small_select"
                        onChange={(e) =>
                          setFilter({ ...filter, last: e.target.value })
                        }
                        value={filter.last}
                        aria-label="Default select example"
                      >
                        <option value={25}>25 Txn</option>
                        <option value={50}>50 Txn</option>
                        <option value={100}>100 Txn</option>
                        <option value={200}>200 Txn</option>
                        <option value={""}>All</option>
                      </Form.Select>
                    </div> */}

                    <div className="bet-sec p-1 bet-period">
                      <Form.Label>Auto Refresh (Seconds) </Form.Label>
                      <Form.Select
                        className="small_select"
                        onChange={(e) =>
                          setFilter({ ...filter, refereshTime: e.target.value })
                        }
                        value={filter?.refereshTime}
                        aria-label="Default select example"
                      >
                        <option value="">Stop</option>
                        <option value={60}>60</option>
                        <option value={30}>30</option>
                        <option value={15}>15</option>
                        <option value={5}>5</option>
                        <option value={2}>2</option>
                      </Form.Select>
                    </div>

                    <div className="bet-sec p-1">
                      <Form.Label>Bet Status:</Form.Label>
                      <Form.Select
                        className="small_select"
                        onChange={(e) =>
                          setFilter({ ...filter, status: e.target.value })
                        }
                        value={filter.status}
                        aria-label="Default select example"
                      >
                        <option value={"active"}>Active</option>
                        <option value={"suspend"}>Suspend</option>
                      </Form.Select>
                    </div>

                    <div style={{ display: "flex" }}>
                      <Button
                        className="theme_light_btn theme_dark_btn"
                        onClick={() => getData(filter)}
                      >
                        Get History
                      </Button>
                    
                    </div>
                  </div>
                  <div id="noReportMessage" class="mb-0">
                    <p className="p-0 m-0">
                      Bet List enables you to review the bets you have placed.{" "}
                      <br />
                      Specify the time period during which your bets were
                      placed, the type of markets on which the bets were placed,
                      and the sport.
                    </p>
                    <p className="p-0 m-0">
                      Bet List is available online for the past 14 days.
                    </p>
                  </div>
                </Form>

                <div className="account-table account-tabel-style batting-table">
                  <div className="responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">PL ID</th>
                          <th scope="col"> Bet ID</th>
                          <th scope="col">
                            Bet{" "}
                            {filter?.betType == "parlay" ? "placed" : "taken"}
                          </th>
                          <th scope="col">IP Address </th>
                          {filter?.betType != "parlay" && (
                            <>
                              <th scope="col">Market</th>
                              <th scope="col">Selection</th>
                            </>
                          )}
                          <th scope="col">Type</th>
                          {filter?.betType != "casino" && (
                            <th scope="col">
                              {filter?.betType == "parlay" ? "Avg." : ""} Odds{" "}
                              {filter?.betType == "parlay" ? "Matched" : "req."}{" "}
                            </th>
                          )}
                          <th scope="col">Stake </th>

                          {filter?.betType != "casino" && (
                            <>
                              <th scope="col">Liability</th>
                              <th scope="col"> Profit/Loss</th>
                            </>
                          )}
                          {!["casino", "parlay"].includes(filter?.betType) && (
                            <>
                              <th scope="col">UnMatched LastPrice</th>
                              <th scope="col">Matched LastPrice</th>
                              <th scope="col">Odds Differential</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      {filter?.betType != "casino" && data?.length > 0
                        ? data?.map((item) => {
                            if (
                              item.isMatched ||
                              !isEmpty(item?.fancyName) ||
                              filter?.betType == "parlay"
                            ) {
                              return (
                                <>
                                  <tr style={{ background: "white" }}>
                                    <td>{item?.clientName || "-"}</td>
                                    <td
                                      style={{
                                        textDecoration: "underline",
                                        color: "#2779bf",
                                      }}
                                    >
                                      {" "}
                                      {getType === "3"
                                        ? item?.casinoBetId
                                        : getBetType === "fancy"
                                        ? item?.sessionBetId
                                        : item?.matchBetId || "-"}
                                      {filter?.betType == "parlay" && (
                                        <div>
                                          {item?.matchBetId == id ? (
                                            <AiFillMinusCircle
                                              onClick={() => {
                                                setId("");
                                              }}
                                              size={16}
                                              style={{ marginRight: "3px" }}
                                              color="rgba(59, 130, 246, 1)"
                                            />
                                          ) : (
                                            <AiFillPlusCircle
                                              size={16}
                                              onClick={() => {
                                                setId(item?.matchBetId);
                                              }}
                                              style={{ marginRight: "3px" }}
                                              color="rgba(59, 130, 246, 1)"
                                            />
                                          )}
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {helpers.dateFormat(
                                        item?.timeInserted,
                                        user.timeZone
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {(item?.ipAddress &&
                                        item?.ipAddress.replace(
                                          "::ffff:",
                                          ""
                                        )) ||
                                        "-"}
                                    </td>
                                    {filter?.betType !== "parlay" && (
                                      <td className="text-start">
                                        {obj.betCheckObj[Number(getType)]}
                                        <span
                                          style={{ background: "transparent" }}
                                          className="angle_unicode"
                                        >
                                          ▸
                                        </span>
                                        <strong
                                          style={{ background: "transparent" }}
                                        >
                                          {getType === "3"
                                            ? item?.casinoName
                                            : item?.matchName}
                                        </strong>
                                        <span
                                          style={{ background: "transparent" }}
                                          className="angle_unicode"
                                        >
                                          ▸
                                        </span>
                                        {getBetType === "betfair" &&
                                          "Match Odds"}{" "}
                                        {getBetType === "Bookmaker" &&
                                          "Book Maker"}
                                        {getBetType === "sportBook" &&
                                          item?.fancyName}
                                        {getBetType === "fancy" &&
                                          item?.fancyName}{" "}
                                        {getBetType === "casino" &&
                                          item?.casinoType}{" "}
                                      </td>
                                    )}
                                    {filter?.betType == "parlay" ? (
                                      <td>
                                        {item?.bets?.length == 1
                                          ? "Single"
                                          : item?.bets?.length == 2
                                          ? "DOUBLE"
                                          : item?.bets?.length > 2
                                          ? "ACCUMULATOR"
                                          : ""}
                                      </td>
                                    ) : (
                                      <td>
                                        {getBetType === "fancy"
                                          ? item?.betRun + "/" + item?.bhav
                                          : getBetType === "sportBook"
                                          ? item?.runnerName
                                          : getBetType === "casino"
                                          ? item?.platformTxId
                                          : item?.teamName}
                                      </td>
                                    )}

                                    {filter?.betType !== "parlay" && (
                                      <td>
                                        {" "}
                                        {item?.betType ||
                                          item?.type ||
                                          item?.gameCode}{" "}
                                      </td>
                                    )}
                                    <td>
                                      {" "}
                                      {getType === "3"
                                        ? 0
                                        : obj.truncateDecimals(item?.bhav,2) || "-"}{" "}
                                    </td>
                                    <td> {item?.amount || "-"} </td>
                                    <td> {item?.loseAmount || "-"} </td>
                                    {filter?.betType == "parlay" ? (
                                      item?.isDeclared ? (
                                        <td>
                                          {item?.result == "win" ? (
                                            <span className="text-success">
                                              {Math.abs(
                                                obj.truncateDecimals(
                                                  item?.profitAmount,
                                                  2
                                                )
                                              )}
                                            </span>
                                          ) : (
                                            <span className="text-danger">
                                              (
                                              {Math.abs(
                                                obj.truncateDecimals(
                                                  item?.loseAmount,
                                                  2
                                                )
                                              )}
                                              )
                                            </span>
                                          )}
                                        </td>
                                      ) : (
                                        <td>Not Settled</td>
                                      )
                                    ) : (
                                      <td>
                                        {getType === "3" ? (
                                          Math.sign(item?.playerPL) === -1 ? (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-danger"
                                            >
                                              (
                                              {helpers.currencyFormat(
                                                item?.playerPL
                                              )}
                                              )
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-success"
                                            >
                                              (
                                              {helpers.currencyFormat(
                                                Math.abs(item?.playerPL)
                                              )}
                                              )
                                            </span>
                                          )
                                        ) : item?.betType == "back" ||
                                          item?.type == "Yes" ? (
                                          <span
                                            style={{
                                              background: "transparent",
                                            }}
                                            className="text-success"
                                          >
                                            {item?.profitAmount}
                                          </span>
                                        ) : (
                                          <span
                                            style={{
                                              background: "transparent",
                                            }}
                                            className="text-danger"
                                          >
                                            -({item?.loseAmount})
                                          </span>
                                        )}
                                      </td>
                                    )}
                                    {!["casino", "parlay"].includes(
                                      filter?.betType
                                    ) && (
                                      <>
                                        <td className="text-center">-</td>{" "}
                                        <td className="text-center">-</td>
                                        <td className="text-center">-</td>
                                      </>
                                    )}
                                  </tr>
                                  {item?.matchBetId == id && (
                                    <tr
                                      style={{ background: "#F3F2F1" }}
                                      className="expand"
                                    >
                                      <td colSpan="9" className="expand_wrap ">
                                        <div
                                          style={{ background: "#F3F2F1" }}
                                          className="d-flex justify-content-center align-items-center"
                                        >
                                          <table className="table-commission table-commission-parlay w-75">
                                            <tbody>
                                              <tr className="header">
                                                <th>Betfair Event Id</th>
                                                <th>Market</th>
                                                <th>Selection</th>
                                                <th>Odds Req.</th>
                                                <th>Odds Matched</th>
                                                <th>Win/Loss</th>
                                              </tr>
                                              {item?.bets?.map((res) => {
                                                return (
                                                  <tr
                                                    id="txRow0"
                                                    style={{
                                                      display: "table-row",
                                                      background: "white",
                                                    }}
                                                    className="even"
                                                  >
                                                    <td id="betID">
                                                      {res?.eventId}
                                                    </td>
                                                    <td>
                                                      {
                                                        obj.betCheckObj[
                                                          res?.eventType
                                                        ]
                                                      }
                                                      <span
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                        className="angle_unicode"
                                                      >
                                                        ▸
                                                      </span>
                                                      <strong
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                      >
                                                        {res?.matchName}
                                                      </strong>
                                                      <span
                                                        style={{
                                                          background:
                                                            "transparent",
                                                        }}
                                                        className="angle_unicode"
                                                      >
                                                        ▸
                                                      </span>
                                                      {res?.fancyName}
                                                    </td>
                                                    <td> {res?.runnerName}</td>
                                                    <td>{res?.bhav}</td>
                                                    <td>-</td>
                                                    {res?.isDeclared ? (
                                                      <td>
                                                        {" "}
                                                        {res?.result ==
                                                        "win" ? (
                                                          <span className="text-success">
                                                            {Math.abs(
                                                              obj.truncateDecimals(
                                                                res?.profitAmount,
                                                                2
                                                              )
                                                            )}
                                                          </span>
                                                        ) : (
                                                          <span className="text-danger">
                                                            (
                                                            {Math.abs(
                                                              obj.truncateDecimals(
                                                                res?.loseAmount,
                                                                2
                                                              )
                                                            )}
                                                            )
                                                          </span>
                                                        )}
                                                      </td>
                                                    ) : (
                                                      <td>Not Settled</td>
                                                    )}
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>{" "}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            }
                          })
                        : null}

                      {filter?.betType == "casino" && data && data?.length > 0
                        ? data?.map((item) => {
                            return (
                              <tr
                                bgcolor={
                                  item?.betType == "back" || item?.type == "Yes"
                                    ? "#72BBEF"
                                    : "#FAA9BA"
                                }
                              >
                                <td>{item?.clientName || "-"}</td>
                                <td> {item?.casinoBetId}</td>
                                <td>
                                  {" "}
                                  {helpers.dateFormat(
                                    item?.timeInserted,
                                    user.timeZone
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {(item?.ipAddress &&
                                    item?.ipAddress.replace("::ffff:", "")) ||
                                    "-"}
                                </td>
                                <td className="text-start">
                                  {obj.betCheckObj[Number(getType)]}
                                  <span
                                    style={{ background: "transparent" }}
                                    className="angle_unicode"
                                  >
                                    ▸
                                  </span>
                                  <strong style={{ background: "transparent" }}>
                                    {getType === "3"
                                      ? item?.casinoName
                                      : item?.matchName}
                                  </strong>
                                  <span
                                    style={{ background: "transparent" }}
                                    className="angle_unicode"
                                  >
                                    ▸
                                  </span>
                                  {getBetType === "betfair" && "Match Odds"}{" "}
                                  {getBetType === "Bookmaker" && "Book Maker"}
                                  {getBetType === "sportBook" && "Sport Book"}
                                  {getBetType === "fancy" &&
                                    item?.fancyName}{" "}
                                  {getBetType === "casino" && item?.casinoType}{" "}
                                </td>
                                <td> {item?.platformTxId}</td>
                                <td> {item?.gameCode} </td>
                                <td> {item?.betAmount || "-"} </td>

                                {/* <td>
                              {getType === "3" ? (
                                Math.sign(item?.playerPL) === -1 ? (
                                  <span className="text-danger">
                                    ({helpers.currencyFormat(item?.playerPL)})
                                  </span>
                                ) : (
                                  <span className="text-success">
                                    (
                                    {helpers.currencyFormat(
                                      Math.abs(item?.playerPL)
                                    )}
                                    )
                                  </span>
                                )
                              ) : item?.betType == "back" || item?.type == "Yes" ? (
                                <span className="text-success">
                                  {item?.profitAmount}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  -({item?.loseAmount})
                                </span>
                              )}
                            </td> */}
                              </tr>
                            );
                          })
                        : null}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default BetListLive;
