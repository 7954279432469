import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import MyAccountSidebar from "../../components/MyAccountSidebar";
import { apiGet, apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { pick } from "lodash";
import { useForm } from "react-hook-form";
import Breadcrumbs from "./Breadcrumbs";
import { toast } from "wc-toast";
import { Link } from "react-router-dom";
import { ResetPassword } from "../../components/ResetPassword";

const MyProfile = () => {
  const [profileData, setProfileData] = useState("");
  const [password_same, set_password_same] = useState(true);
  const [isLoader, setLoader] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const changePasswordToggle = () => setChangePassword(!changePassword);
  const { reset } = useForm({});
  const myProfile = async () => {
    const { status, data: response_users } = await apiGet(apiPath.userProfile);
    if (status === 200) {
      if (response_users.success) {
        setProfileData(response_users.results);
      }
    }
  };

  const onSubmit = async (request) => {
    setLoader(true);
    set_password_same(true);

    if (request.newPassword !== request.confirmPassword) {
      setLoader(false);
      set_password_same(false);
    } else {
      const { status, data: response_users } = await apiPost(
        apiPath.changePassword,
        pick(request, ["oldPassword", "newPassword"])
      );
      if (status === 200) {
        if (response_users.success) {
          setLoader(false);
          setChangePassword();
          toast.success(response_users.message);
          reset();
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      }
    }
  };

  useEffect(() => {
    myProfile();
  }, []);

  return (
    <div>
      <div className="user_data_div">
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <Breadcrumbs />
          <Row>
            {/* <Col lg={3}>
              <MyAccountSidebar />
            </Col> */}
            <Col lg={9} md={12}>
              <div className="inner-wrapper">
                {/* profile */}

                <h2>Profile</h2>
                <div className="account-table">
                  <div className="profile-tab table-color">
                    <Row>
                      <Col md={7}>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                colSpan="4"
                                className="text-start"
                              >
                                About You
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-start" width="25%">
                                First Name
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.firstName}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Last Name
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.lastName}
                              </td>
                            </tr>

                            <tr>
                              <td className="text-start" width="25%">
                                Birthday
                              </td>
                              <td className="text-start" colSpan="3">
                                -----
                              </td>
                            </tr>

                            <tr>
                              <td className="text-start" width="25%">
                                Email
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.email}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Password
                              </td>
                              <td className="text-start">************</td>
                              <td>
                                <Link
                                  to="#"
                                  className="text-decoration-none btn theme_light_btn"
                                  onClick={changePasswordToggle}
                                >
                                  Edit{" "}
                                  <i className="fas fa-pen ps-1"></i>
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Exposure
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.exposure}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start" width="25%">
                                Time Zone
                              </td>
                              <td className="text-start" colSpan="3">
                                {profileData.timeZone}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>

                      <Col md={5}>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                colSpan="4"
                                className="text-start"
                              >
                                Contact Details
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-start">Primary Number</td>
                              <td className="text-start">
                                {" "}
                                {profileData.phone}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* profile */}
              </div>
            </Col>
          </Row>
        </Container>
      </section></div>
      {/* change-password */}
      <ResetPassword
        changePassword={changePassword}
        changePasswordToggle={changePasswordToggle}
        onSubmit={onSubmit}
        isLoader={isLoader}
        password_same={password_same}
      />
    </div>
  );
};

export default MyProfile;
