import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import ReactPaginate from "react-paginate";
import AuthContext from "../context/AuthContext";
import obj from "../utils/helpers";
import { toast } from "wc-toast";
import { Link, useLocation, useParams } from "react-router-dom";
import Search from "./Users/Search";
import constants from "../utils/constants";
import { compact, isEmpty, startCase } from "lodash";

const P2pTransfer = (props) => {
  let { user } = useContext(AuthContext);
  let user_by_created = user.id;
  let userType = user.userType;
  const [show_model, setShowModel] = useState(false);
  const [reset, setRest] = useState(false);
  const [results, setResults] = useState([]);
  const [transferAmount, setTransferAmount] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
    keyword: "",
    status: "active",
    created_by: user_by_created,
    userType: userType,
  });
  const [balance, setBalance] = useState({
    totalAvailableLimit: 0,
    totalAmount: 0,
    totalCoins: 0,
    playerBalance: 0,
    availableLimit: 0,
    exposure: 0,
  });

  const onSubmit = (params) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        keyword: params.keyword,
        status: params.status,
      };
    });
  };

  const handlePageClick = (event) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  const getUsers = async (obj) => {
    const { status, data: response_users } = await apiGet(
      apiPath.profileList,
      !isEmpty(obj) ? obj : search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setResults(response_users?.results || []);
      }
    }
    setRest(false);
  };

  const resetList = () => {
    setRest(true);
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        page_size: 10,
        keyword: "",
        status: "active",
      };
    });
  };

  useEffect(() => {
    getUsers();
  }, [
    search_params?.page,
    search_params?.keyword,
    search_params?.status,
    search_params?.created_by,
    search_params?.userType,
  ]);


  useEffect(() => {
    getUsers(search_params);
  }, [search_params]);

  useEffect(() => {
    setPageCount(Math.ceil(results?.totalPages) || 0);
  }, [results?.data]);

  // console.log('results ------------------ ',results);

  return (
    <div>
      <div className="user_data_div">
        <section className="main-inner-outer py-4">
          <Container fluid>
            <div className="find-member-sec search_banking_detail" style={{marginBottom:"10px"}}>
              <Search
                onSubmit={onSubmit}
                search_params={search_params}
                reset={reset}
              />
            </div>
            <div className="inner-wrapper">
              <div className="common-container">
                <div className="account-table batting-table">
                  <div className="responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Account</th>
                          <th scope="col">Enable/Disable All</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results?.data && results?.data?.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td class="text-start">
                                <Link to={`/${user?._id}/${user?.userType}`} className="text-primary" >
                                  <span></span>{`${user?.username || '-----'}`}
                                </Link>
                              </td>

                              <td>
                                <Form.Check type="switch"
                                  checked={user?.enableTransfer ? true : false}
                                  onClick={function (e) {
                                    setTransferAmount({
                                      id: user?._id,
                                      status: user?.enableTransfer ? false : true,
                                      type: "enableTransfer",
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                        {isEmpty(results?.data) ? (
                          <tr>
                            <td colSpan={2}>No records found</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                    <div className="bottom-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >"
                        onPageChange={props.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                        activeClassName="p-0"
                        activeLinkClassName="pagintion-li"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default P2pTransfer;
