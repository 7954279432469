import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import obj from "../utils/constants";
import apiPath from "../utils/apiPath";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import helpers from "../utils/helpers";
import AuthContext from "../context/AuthContext";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
const BetList = () => {
  let { user } = useContext(AuthContext);
  const [id, setId] = useState("");
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [pageCount, setPageCount] = useState(0);
  const [viewpage, setViewPage] = useState(0);
  const [data, setData] = useState([]);
  const [getBetType, setBetType] = useState("betfair");
  const [getType, setType] = useState("4");
  const location = useLocation();
  const [filter, setFilter] = useState({
    type: "4",
    betType: "betfair",
    status: "completed",
    fromPeriod: previousDate,
    toPeriod: currentDate,
    filterByDay: "",
    page: 1,
    limit: 100,
  });

  const getData = async (type = "search") => {
    if (filter.filterByDay != "") {
      if (filter.filterByDay == "today") {
        filter.fromPeriod = currentDate;
        filter.toPeriod = currentDate;
      }
      if (filter.filterByDay == "yesterday") {
        filter.fromPeriod = previousDate;
        filter.toPeriod = currentDate;
      }
    }
    const { status, data: response_users } = await apiGet(
      apiPath.getBets,
      type == "search" ? filter : type
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
        setBetType(filter.betType);
        setType(filter.type);
      }
    }
  };

  useEffect(() => {
    if (filter.filterByDay != "") {
      getData();
    }
  }, [filter.filterByDay]);

  const handleSearch = (type) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: 1,
      };
    });
    getData(type);
  };
  const handlePageClick = (event) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
    setViewPage(event.selected);
  };

  useEffect(() => {
    setPageCount(data?.totalPages || []);
  }, [data]);

  useEffect(() => {
    getData();
    setViewPage(filter.page ? filter.page - 1 : 0);
  }, [
    filter?.type,
    filter?.betType,
    filter?.status,
    filter?.type,
    filter?.page,
  ]);

  return (
    <div>
      <div className="user_data_div p-1">
        <section className="main-inner-outer py-3">
          <Container fluid>
            <div className="inner-wrapper">
              <div className="common-container">
                <Form className="">
                  <div className="bet_status border-0 mb-0">
                    <div className="d-flex align-items-center mb-1">
                      <div className="list-unstyled mb-0 d-flex">
                        {obj.betCheckData.map((type) => {
                          return (
                            <div>
                              <Form.Check
                                inline
                                label={type.value}
                                name={type.label}
                                type="radio"
                                checked={
                                  type.label === filter.type ? true : false
                                }
                                onChange={(e) =>
                                  setFilter({
                                    ...filter,
                                    type: e.target.name,
                                    betType:
                                      type?.value == "Casino"
                                        ? "casino"
                                        : "betfair",
                                  })
                                }
                                id={`inline-radio-1`}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {filter.type != 3 && (
                        <div className="list-unstyled mb-0 d-flex">
                          {obj.betCheckDataInner.map((type) => {
                            return (
                              <div>
                                <Form.Check
                                  inline
                                  label={type.value}
                                  name={type.label}
                                  type="radio"
                                  checked={
                                    type.label === filter.betType ? true : false
                                  }
                                  onChange={(e) =>
                                    setFilter({
                                      ...filter,
                                      betType: e.target.name,
                                    })
                                  }
                                  id={`inline-radio-1`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <Row>
                      <Col lg={2} sm={3} className="mb-lg-0 mb-1">
                        <div className="bet-sec">
                          <Form.Label className="mt-2 me-2">
                            Bet Status:
                          </Form.Label>
                          <Form.Select
                            className="small_select"
                            onChange={(e) =>
                              setFilter({
                                ...filter,
                                status: e.target.value,
                              })
                            }
                            value={filter.status}
                            aria-label="Default select example"
                          >
                            {filter.betType == "betfair" &&
                              (filter.type == 4 ||
                                filter.type == 2 ||
                                filter.type == 1) && (
                                <>
                                  {" "}
                                  <option value="unmatched">Unmatched</option>
                                  <option value="matched">Matched</option>
                                </>
                              )}

                            <option value="completed">Settled</option>
                            <option value="suspend">Cancelled</option>
                            <option value="voided">Voided</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col lg={2} sm={3} className="mb-lg-0 mb-1">
                        <div className="bet-sec bet-period">
                          <Form.Label className="mt-2 me-2">From</Form.Label>
                          <Form.Group className="form-group">
                            <Form.Control
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  fromPeriod: e.target.value,
                                  filterByDay: "",
                                })
                              }
                              max={new Date().toISOString().split("T")[0]}
                              value={filter.fromPeriod}
                              type="date"
                            />
                            <Form.Control
                              className="small_form_control"
                              type="text"
                              placeholder="00:00"
                              disabled
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={2} sm={3} className="mb-lg-0 mb-1">
                        <div className="bet-sec bet-period">
                          <Form.Label className="mt-2 me-2">To</Form.Label>
                          <Form.Group className="form-group">
                            <Form.Control
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  toPeriod: e.target.value,
                                  filterByDay: "",
                                })
                              }
                              min={
                                filter?.fromPeriod
                                  ? new Date(filter?.fromPeriod)
                                      .toISOString()
                                      .split("T")[0]
                                  : new Date()
                              }
                              disabled={filter.fromPeriod ? false : true}
                              max={new Date().toISOString().split("T")[0]}
                              value={filter.toPeriod}
                              type="date"
                            />
                            <Form.Control
                              className="small_form_control"
                              type="text"
                              placeholder="23:59"
                              disabled
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={2} sm={3} className="mb-lg-0 mb-1">
                        <div className="history-btn mb-3">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <Button
                                className={"me-0 theme_light_btn"}
                                onClick={(e) =>
                                  setFilter({
                                    ...filter,
                                    filterByDay: "today",
                                  })
                                }
                              >
                                Just For Today
                              </Button>
                            </li>
                            <li>
                              <Button
                                className={"me-0 theme_light_btn"}
                                onClick={(e) =>
                                  setFilter({
                                    ...filter,
                                    filterByDay: "yesterday",
                                  })
                                }
                              >
                                From Yesterday
                              </Button>
                            </li>
                            <li>
                              <Button
                                className="theme_light_btn theme_dark_btn"
                                onClick={() => handleSearch("search")}
                              >
                                Get History
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>

                    <div id="noReportMessage" class="mb-0">
                      <p className="p-0 m-0">
                        Bet List enables you to review the bets you have placed.{" "}
                        <br />
                        Specify the time period during which your bets were
                        placed, the type of markets on which the bets were
                        placed, and the sport.
                      </p>
                      <p className="p-0 m-0">
                        Bet List is available online for the past 14 days.
                      </p>
                    </div>
                  </div>
                </Form>

                <div className="account-table account-tabel-style batting-table">
                  <div className="responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">PL ID</th>
                          <th scope="col"> Bet ID</th>
                          <th scope="col">
                            Bet{" "}
                            {filter?.betType == "parlay" ? "placed" : "taken"}
                          </th>
                          <th scope="col">IP Address </th>
                          {filter?.betType != "parlay" && (
                            <>
                              <th scope="col">Market</th>
                              <th scope="col">Selection</th>
                            </>
                          )}

                          <th scope="col">Type</th>
                          {filter?.betType != "casino" && (
                            <th scope="col">
                              {filter?.betType == "parlay" ? "Avg." : ""} Odds{" "}
                              {filter?.betType == "parlay" ? "Matched" : "req."}{" "}
                            </th>
                          )}
                          <th scope="col">Stake </th>
                          {filter?.betType != "casino" && (
                            <th scope="col">Liability</th>
                          )}
                          <th scope="col"> Profit/Loss</th>
                          {!["casino", "parlay"].includes(filter?.betType) && (
                            <>
                              <th scope="col">UnMatched LastPrice</th>
                              <th scope="col">Matched LastPrice</th>
                              <th scope="col">Odds Differential</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      {data?.data && data?.data?.length > 0 ? (
                        data?.data?.map((item) => {
                          return (
                            <>
                              <tr>
                                <td>{item?.clientName || "-"}</td>
                                <td
                                  style={{
                                    textDecoration: "underline",
                                    color: "#2779bf",
                                  }}
                                >
                                  {" "}
                                  {getType === "3"
                                    ? item?.casinoBetId
                                    : item?.matchBetId || "-"}
                                  {filter?.betType == "parlay" && (
                                    <div>
                                      {item?.matchBetId == id ? (
                                        <AiFillMinusCircle
                                          onClick={() => {
                                            setId("");
                                          }}
                                          size={16}
                                          style={{ marginRight: "3px" }}
                                          color="rgba(59, 130, 246, 1)"
                                        />
                                      ) : (
                                        <AiFillPlusCircle
                                          size={16}
                                          onClick={() => {
                                            setId(item?.matchBetId);
                                          }}
                                          style={{ marginRight: "3px" }}
                                          color="rgba(59, 130, 246, 1)"
                                        />
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {helpers.dateFormat(
                                    item?.timeInserted,
                                    user.timeZone
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {(item?.ipAddress &&
                                    item?.ipAddress.replace("::ffff:", "")) ||
                                    "-"}
                                </td>
                                {filter?.betType !== "parlay" && (
                                  <td className="text-start">
                                    {obj.betCheckObj[getType]}
                                    <span
                                      style={{ background: "transparent" }}
                                      className="angle_unicode"
                                    >
                                      ▸
                                    </span>
                                    <strong
                                      style={{ background: "transparent" }}
                                    >
                                      {getType === "3"
                                        ? item?.casinoName
                                        : item?.matchName}
                                    </strong>
                                    <span
                                      style={{ background: "transparent" }}
                                      className="angle_unicode"
                                    >
                                      ▸
                                    </span>
                                    {getBetType === "betfair" && "Match Odds"}{" "}
                                    {getBetType === "Bookmaker" && "Book Maker"}
                                    {getBetType === "sportBook" &&
                                      item?.fancyName}
                                    {getBetType === "fancy" && item?.fancyName}{" "}
                                    {getBetType === "casino" &&
                                      item?.casinoType}{" "}
                                  </td>
                                )}
                                {filter?.betType == "parlay" ? (
                                  <td>
                                    {item?.bets?.length == 1
                                      ? "Single"
                                      : item?.bets?.length == 2
                                      ? "DOUBLE"
                                      : item?.bets?.length > 2
                                      ? "ACCUMULATOR"
                                      : ""}
                                  </td>
                                ) : (
                                  <td>
                                    {getBetType === "fancy"
                                      ? item?.betRun + "/" + item?.bhav
                                      : getBetType === "sportBook"
                                      ? item?.runnerName
                                      : getBetType === "casino"
                                      ? item?.platformTxId
                                      : item?.teamName}
                                  </td>
                                )}

                                {filter?.betType !== "parlay" && (
                                  <td>
                                    {" "}
                                    {item?.betType ||
                                      item?.type ||
                                      item?.gameCode}{" "}
                                  </td>
                                )}
                                {filter?.betType != "casino" && (
                                  <td>
                                    {" "}
                                    {getType === "3"
                                      ? 0
                                      : item?.bhav || "-"}{" "}
                                  </td>
                                )}
                                <td>
                                  {" "}
                                  {filter?.betType != "casino"
                                    ? item?.amount
                                    : item?.betAmount}{" "}
                                </td>
                                {filter?.betType != "casino" && (
                                  <td> {item?.loseAmount || "-"} </td>
                                )}
                                {filter?.betType == "parlay" ? (
                                  item?.isDeclared ? (
                                    <td>
                                      {item?.result == "win" ? (
                                        <span className="text-success">
                                          {Math.abs(
                                            obj.truncateDecimals(
                                              item?.profitAmount,
                                              2
                                            )
                                          )}
                                        </span>
                                      ) : (
                                        <span className="text-danger">
                                          (
                                          {Math.abs(
                                            obj.truncateDecimals(
                                              item?.loseAmount,
                                              2
                                            )
                                          )}
                                          )
                                        </span>
                                      )}
                                    </td>
                                  ) : (
                                    <td>Not Settled</td>
                                  )
                                ) : item?.status !== "voided" ? (
                                  filter?.betType != "casino" ? (
                                    <td>
                                      {getBetType == "betfair" ||
                                      getBetType === "Bookmaker" ||
                                      getBetType === "toss" ||
                                      getBetType === "tie" ? (
                                        <>
                                          {(item?.teamSelectionWin ==
                                            item?.selectionId &&
                                            item?.betType == "back") ||
                                          (item?.teamSelectionWin !=
                                            item?.selectionId &&
                                            item?.betType == "lay") ? (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-success"
                                            >
                                              {item?.profitAmount}
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-danger"
                                            >
                                              -({item?.loseAmount})
                                            </span>
                                          )}
                                        </>
                                      ) : getBetType === "sportBook" ? (
                                        <>
                                          {item?.teamSelectionWin &&
                                          item?.teamSelectionWin
                                            .split(",")
                                            .includes(
                                              item?.fancySelectionId
                                            ) ? (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-success"
                                            >
                                              {item?.profitAmount}
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-danger"
                                            >
                                              -({item?.loseAmount})
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.type == "No" ? (
                                            item?.decisionRun < item?.betRun ? (
                                              <span
                                                style={{
                                                  background: "transparent",
                                                }}
                                                className="text-success"
                                              >
                                                {item?.profitAmount}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  background: "transparent",
                                                }}
                                                className="text-danger"
                                              >
                                                -({item?.loseAmount})
                                              </span>
                                            )
                                          ) : item?.decisionRun >=
                                            item?.betRun ? (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-success"
                                            >
                                              {item?.profitAmount}
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-danger"
                                            >
                                              -({item?.loseAmount})
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  ) : (
                                    <td className="text-end">
                                      {getBetType == "betfair" ||
                                      getBetType === "Bookmaker" ? (
                                        <>
                                          {(item?.teamSelectionWin ==
                                            item?.selectionId &&
                                            item?.betType == "back") ||
                                          (item?.teamSelectionWin !=
                                            item?.selectionId &&
                                            item?.betType == "lay") ? (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-success"
                                            >
                                              {item?.profitAmount}
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-danger"
                                            >
                                              -({item?.loseAmount})
                                            </span>
                                          )}
                                        </>
                                      ) : getBetType === "sportBook" ? (
                                        <>
                                          {item?.teamSelectionWin &&
                                          item?.teamSelectionWin
                                            .split(",")
                                            .includes(
                                              item?.fancySelectionId
                                            ) ? (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-success"
                                            >
                                              {item?.profitAmount}
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-danger"
                                            >
                                              -({item?.loseAmount})
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {Math.sign(item?.realCutAmount) ===
                                          -1 ? (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-danger"
                                            >
                                              (
                                              {helpers.currencyFormat(
                                                item?.realCutAmount
                                              )}
                                              )
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                background: "transparent",
                                              }}
                                              className="text-success"
                                            >
                                              (
                                              {helpers.currencyFormat(
                                                Math.abs(item?.realCutAmount)
                                              )}
                                              )
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  )
                                ) : (
                                  <td>0.00</td>
                                )}
                                {!["casino", "parlay"].includes(
                                  filter?.betType
                                ) && (
                                  <>
                                    <td className="text-center">-</td>{" "}
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                  </>
                                )}
                              </tr>
                              {item?.matchBetId == id && (
                                <tr
                                  style={{ background: "#F3F2F1" }}
                                  className="expand"
                                >
                                  <td colSpan="9" className="expand_wrap ">
                                    <div
                                      style={{ background: "#F3F2F1" }}
                                      className="d-flex justify-content-center align-items-center"
                                    >
                                      <table className="table-commission table-commission-parlay w-75">
                                        <tbody>
                                          <tr className="header">
                                            <th>Betfair Event Id</th>
                                            <th>Market</th>
                                            <th>Selection</th>
                                            <th>Odds Req.</th>
                                            <th>Odds Matched</th>
                                            <th>Win/Loss</th>
                                          </tr>
                                          {item?.bets?.map((res) => {
                                            return (
                                              <tr
                                                id="txRow0"
                                                style={{
                                                  display: "table-row",
                                                  background: "white",
                                                }}
                                                className="even"
                                              >
                                                <td id="betID">
                                                  {res?.eventId}
                                                </td>
                                                <td>
                                                  {
                                                    obj.betCheckObj[
                                                      res?.eventType
                                                    ]
                                                  }
                                                  <span
                                                    style={{
                                                      background: "transparent",
                                                    }}
                                                    className="angle_unicode"
                                                  >
                                                    ▸
                                                  </span>
                                                  <strong
                                                    style={{
                                                      background: "transparent",
                                                    }}
                                                  >
                                                    {res?.matchName}
                                                  </strong>
                                                  <span
                                                    style={{
                                                      background: "transparent",
                                                    }}
                                                    className="angle_unicode"
                                                  >
                                                    ▸
                                                  </span>
                                                  {res?.fancyName}
                                                </td>
                                                <td> {res?.runnerName}</td>
                                                <td>{res?.bhav}</td>
                                                <td>-</td>
                                                {res?.isDeclared ? (
                                                  <td>
                                                    {" "}
                                                    {res?.result == "win" ? (
                                                      <span className="text-success">
                                                        {Math.abs(
                                                          obj.truncateDecimals(
                                                            res?.profitAmount,
                                                            2
                                                          )
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <span className="text-danger">
                                                        (
                                                        {Math.abs(
                                                          obj.truncateDecimals(
                                                            res?.loseAmount,
                                                            2
                                                          )
                                                        )}
                                                        )
                                                      </span>
                                                    )}
                                                  </td>
                                                ) : (
                                                  <td>Not Settled</td>
                                                )}
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>{" "}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <span>You have no bets in this time period.</span>
                          </td>
                        </tr>
                      )}
                    </Table>
                  </div>
                  <div className="bottom-pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=" >"
                      forcePage={viewpage}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={10}
                      pageCount={pageCount}
                      previousLabel="< "
                      renderOnZeroPageCount={null}
                      activeClassName="p-1"
                      activeLinkClassName="pagintion-li"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default BetList;
