import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import obj from "../../utils/constants";
import DropDown from "./DropDown";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import { FancyListDropdown } from "./FancyListDropdown";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { FaArrowAltCircleRight } from "react-icons/fa";
import ListHeader from "./ListHeader";

const FancyBetTypeListingNew = ({
  title,
  data,
  socketOddsWork,
  getFancyData,
  fancyOdds,
  setFancyOddds,
  tileFile,
  refresh,
}) => {
  const navigate = useNavigate();
  const [showMatch, setShowMatch] = useState(false);
  let { user } = useContext(AuthContext);
  const [selectData, setSelectData] = useState([]);
  const [id, setId] = useState("");
  return (
    <div className="risk-management-table">
      <ListHeader
        title={title}
        downloadShow={data?.length > 0 ? true : false}
        data={data}
        fileTile={tileFile}
        refresh={refresh}
        csvData={
          data?.length > 0
            ? [
                [
                  "Sports",
                  "Market Date",
                  "Event/Market Name",
                  "Player P/L Min",
                  "Player P/L Max",
                ],
                ...data?.map(
                  ({
                    eventType,
                    timeInserted,
                    positionProfitAmount,
                    positionLoseAmount,
                    matchName,
                    fancyName,
                  }) => [
                    obj?.betCheckObj[eventType],
                    timeInserted,
                    `${matchName} -> ${fancyName}`,
                    positionLoseAmount,
                    positionProfitAmount,
                  ]
                ),
              ]
            : []
        }
      />
      {data?.length > 0 ? (
        <div className="account-table match-odd-table match-odd-table-new">
          <div className="responsive">
            <Table>
              <tbody className="header-new">
                <tr>
                  <td width="10%" rowSpan="2">
                    <strong
                      style={{ color: "white", textDecoration: "underline" }}
                    >
                      {" "}
                      Sports
                    </strong>
                  </td>
                  <td rowSpan="2">
                    <strong></strong>
                  </td>
                  <td width="7%" className="border-0">
                    <strong> Min</strong>
                  </td>

                  <td width="7%" className="border-0"></td>
                  <td width="7%" className="border-0">
                    <strong>{"Max"}</strong>
                  </td>

                  <td
                    style={{ borderLeft: "1px solid #d1d5dbcc" }}
                    rowSpan="2"
                    width={"8%"}
                    className="text-center"
                  >
                    <strong> Downline P/L</strong>
                  </td>
                </tr>
              </tbody>
              <tbody className="match-tbody">
                {isEmpty(data) && (
                  <tr>
                    <td colSpan={7}>No Record Found</td>
                  </tr>
                )}

                {data?.length > 0 &&
                  data.map((res, index) => {
                    return (
                      <>
                        <tr key={index + 1}>
                          {/* <td className="text-center">
                            <p>{`${
                              res?.eventType === "4"
                                ? "Cricket"
                                : res?.eventType === "1"
                                ? "Soccer"
                                : res?.eventType === "2"
                                ? "Tennis"
                                : "Casino"
                            }`}</p>
                          </td> */}
                          <td
                            style={{ borderRight: "1px solid #d1d5dbcc" }}
                            className="text-center"
                          >
                            {dayjs(res.eventDateTime).format("YYYY-MM-DD")}
                          </td>
                          <td className="bg-yellow border-0">
                            <a>
                              <>
                                {index + 1 == id ? (
                                  <AiFillMinusCircle
                                    onClick={() => {
                                      setShowMatch(false);
                                      setSelectData("");
                                      setId("");
                                      getFancyData(
                                        res?.eventId,
                                        res?.selectionId
                                      );
                                      setFancyOddds({});
                                    }}
                                    size={20}
                                    style={{ marginRight: "3px" }}
                                    color="rgba(59, 130, 246, 1)"
                                  />
                                ) : (
                                  <AiFillPlusCircle
                                    size={20}
                                    onClick={() => {
                                      setShowMatch(true);
                                      setId(index + 1);
                                      setSelectData(res?.odds);
                                      getFancyData(
                                        res?.eventId,
                                        res?.selectionId
                                      );
                                    }}
                                    style={{ marginRight: "3px" }}
                                    color="rgba(59, 130, 246, 1)"
                                  />
                                )}
                              </>
                              <strong
                                onClick={() =>
                                  navigate(
                                    `/match-details/${res?.eventId}/${res?.marketId}`
                                  )
                                }
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "#2779bf",
                                }}
                              >
                                {res?.matchName}
                              </strong>{" "}
                              <FaArrowAltCircleRight
                                style={{ margin: "0 3px" }}
                                size={14}
                                color="rgba(107, 114, 128, 1"
                              />
                              <span className="ms-3">{res?.fancyName} </span>
                            </a>
                          </td>
                          <td className="border-0 bg-yellow">
                            <p className="text-danger">
                              {res?.positionLoseAmount
                                ? "(-" + res?.positionLoseAmount + ")"
                                : 0}
                            </p>
                          </td>

                          <td className="border-0 bg-yellow"></td>

                          <td className="border-0 bg-yellow">
                            <p className="text-success">
                              {res?.positionProfitAmount
                                ? res?.positionProfitAmount
                                : 0}
                            </p>
                          </td>
                          <td
                            style={{ borderLeft: "1px solid #d1d5dbcc" }}
                            className="border-right-0 text-center"
                          >
                            <Link
                              onClick={() => {
                                window
                                  .open(
                                    `/DownlinePnl-Fancy/${res?.fancyName}/${res?.eventId}/${res?.marketId}/${res?.selectionId}`,
                                    "_blank",
                                    "width=900, height=700"
                                  )
                                  .focus();
                              }}
                              className="btn-light-view"
                            >
                              {"Book"}
                            </Link>
                          </td>
                        </tr>

                        {index + 1 == id ? (
                          <FancyListDropdown
                            showMatch={showMatch}
                            data={res?.jsonData}
                            selectData={selectData}
                            res={res}
                            title={title}
                            fancyOdds={
                              fancyOdds?.rt?.length > 0 &&
                              res?.selectionId == fancyOdds?.selectionId
                                ? fancyOdds?.rt
                                : []
                            }
                          />
                        ) : (
                          ""
                        )}
                      </>
                    );
                    //   }
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div id="noData_MATCH_ODDS" class="bg-blueGray-200 p-4 mb-2">
          There are currently no matched bets for this markets
        </div>
      )}
    </div>
  );
};

export default FancyBetTypeListingNew;
