export const limitSetting = [
  {
    label: "Exposure Limit:",
    placeholder: "Enter Expouser Limit",
    key: "exposureLimit",
    keyCheckbox: "isExposureLimit",
  },
  {
    label: "Bookmaking commission:",
    placeholder: "Enter Bookmaking commission",
    key: "bookmakingCommission",
    keyCheckbox: "isBookmakingCommission",
  },
  {
    label: "Exchange commission:",
    placeholder: "Enter Exchange commission",
    key: "exchangeCommission",
    keyCheckbox: "isExchangeCommission",
  },
  {
    label: "Bet Delay(seconds):",
    placeholder: "Enter Bet Delay",
    key: "betDelay",
    keyCheckbox: "isBetDelay",
  },
  {
    label: "No. of Minutes Before Inplay:",
    placeholder: "Enter No. of Minutes Before Inplay",
    key: "minutesBeforeInPlay",
    keyCheckbox: "isMinutesBeforeInPlay",
  },
];

export const marketSetting = [
  {
    label: "Bet Min Rate:",
    placeholder: "Enter Bet Min Rate",
    key: "minRate",
    keyCheckbox: "isMinRate",
  },
  {
    label: "Bet Max Rate:",
    placeholder: "Enter Bet Max Rate",
    key: "maxRate",
    keyCheckbox: "isMaxRate",
  },
  {
    label: "Min Stake:",
    placeholder: "Enter Min Stake",
    key: "minStake",
    keyCheckbox: "isMinStake",
  },
  {
    label: "Max Stake:",
    placeholder: "Enter Max Stake",
    key: "maxStake",
    keyCheckbox: "isMaxStake",
  },
  {
    label: "Max Profit:",
    placeholder: "Enter Max Profit",
    key: "maxProfit",
    keyCheckbox: "isMaxProfit",
  },
  {
    label: "Max Loss:",
    placeholder: "Enter Max Loss",
    key: "maxLoss",
    keyCheckbox: "isMaxLoss",
  },
];

export const sessionSetting = [
  {
    label: "Min Stake:",
    placeholder: "Enter Min Stake",
    key: "minStake",
    keyCheckbox: "isMinStake",
  },
  {
    label: "Max Stake:",
    placeholder: "Enter Max Stake",
    key: "maxStake",
    keyCheckbox: "isMaxStake",
  },
  {
    label: "Max Loss:",
    placeholder: "Enter Max Loss",
    key: "maxLoss",
    keyCheckbox: "isMaxLoss",
  },
  {
    label: "Max Profit:",
    placeholder: "Enter Max Profit",
    key: "maxProfit",
    keyCheckbox: "isMaxProfit",
  },
  {
    label: "Per Rate Max Stake:",
    placeholder: "Enter Per Rate Max Stake",
    key: "perRateMaxStake",
    keyCheckbox: "isPerRateMaxStake",
  },
  {
    label: "Commission(%):",
    placeholder: "Enter Commission(%)",
    key: "commission",
    keyCheckbox: "isCommission",
  },
  {
    label: "Bet Delay:",
    placeholder: "Enter Bet Delay",
    key: "betDelay",
    keyCheckbox: "isBetDelay",
  },
];

export const cricketBookmakingSetting = [
  {
    label: "Bet Delay:",
    placeholder: "Enter Bet Delay",
    key: "betDelay",
    keyCheckbox: "isBetDelay",
  },
  {
    label: "Min Stake:",
    placeholder: "Enter Min Stake",
    key: "minStake",
    keyCheckbox: "isMinStake",
  },
  {
    label: "Max Stake:",
    placeholder: "Enter Max Stake",
    key: "maxStake",
    keyCheckbox: "isMaxStake",
  },
  {
    label: "Max Profit:",
    placeholder: "Enter Max Profit",
    key: "maxProfit",
    keyCheckbox: "isMaxProfit",
  },
  {
    label: "Max Loss:",
    placeholder: "Enter Max Loss",
    key: "maxLoss",
    keyCheckbox: "isMaxLoss",
  },
  {
    label: "Max Stake Per Rate:",
    placeholder: "Enter Max Stake Per Rate",
    key: "maxStakePerRate",
    keyCheckbox: "isMaxStakePerRate",
  },
];

export const soccerBookmakingSetting = [
  {
    label: "Bet Delay:",
    placeholder: "Enter Bet Delay",
    key: "betDelay",
    keyCheckbox: "isBetDelay",
  },
  {
    label: "Min Stake:",
    placeholder: "Enter Min Stake",
    key: "minStake",
    keyCheckbox: "isMinStake",
  },
  {
    label: "Max Stake:",
    placeholder: "Enter Max Stake",
    key: "maxStake",
    keyCheckbox: "isMaxStake",
  },
  {
    label: "Max Profit:",
    placeholder: "Enter Max Profit",
    key: "maxProfit",
    keyCheckbox: "isMaxProfit",
  },
  {
    label: "Max Loss:",
    placeholder: "Enter Max Loss",
    key: "maxLoss",
    keyCheckbox: "isMaxLoss",
  },
  {
    label: "Max Stake Per Rate:",
    placeholder: "Enter Max Stake Per Rate",
    key: "maxStakePerRate",
    keyCheckbox: "isMaxStakePerRate",
  },
];

export const tennisBookmakingSetting = [
  {
    label: "Bet Delay:",
    placeholder: "Enter Bet Delay",
    key: "betDelay",
    keyCheckbox: "isBetDelay",
  },
  {
    label: "Min Stake:",
    placeholder: "Enter Min Stake",
    key: "minStake",
    keyCheckbox: "isMinStake",
  },
  {
    label: "Max Stake:",
    placeholder: "Enter Max Stake",
    key: "maxStake",
    keyCheckbox: "isMaxStake",
  },
  {
    label: "Max Profit:",
    placeholder: "Enter Max Profit",
    key: "maxProfit",
    keyCheckbox: "isMaxProfit",
  },
  {
    label: "Max Loss:",
    placeholder: "Enter Max Loss",
    key: "maxLoss",
    keyCheckbox: "isMaxLoss",
  },
  {
    label: "Max Stake Per Rate:",
    placeholder: "Enter Max Stake Per Rate",
    key: "maxStakePerRate",
    keyCheckbox: "isMaxStakePerRate",
  },
];

export const valuesSetting = {
  limitSetting: {
    exposureLimit: "",
    isExposureLimit: false,
    bookmakingCommission: "",
    isBookmakingCommission: false,
    exchangeCommission: "",
    isExchangeCommission: false,
    betDelay: "",
    isBetDelay: false,
    minutesBeforeInPlay: "",
    isMinutesBeforeInPlay: false,
  },
  marketSetting: {
    minRate: "",
    isMinRate: false,
    maxRate: "",
    isMaxRate: false,
    minStake: "",
    isMinStake: false,
    maxStake: "",
    isMaxStake: false,
    maxProfit: "",
    isMaxProfit: false,
    maxLoss: "",
    isMaxLoss: false,
  },
  sessionSetting: {
    minStake: "",
    isMinStake: false,
    maxStake: "",
    isMaxStake: false,
    maxProfit: "",
    isMaxProfit: false,
    maxLoss: "",
    isMaxLoss: false,
    perRateMaxStake: "",
    isPerRateMaxStake: false,
    commission: "",
    isCommission: false,
    betDelay: "",
    isBetDelay: false,
  },
  cricketBookmakingSetting: {
    betDelay: "",
    isBetDelay: false,
    minStake: "",
    isMinStake: false,
    maxStake: "",
    isMaxStake: false,
    maxProfit: "",
    isMaxProfit: false,
    maxLoss: "",
    isMaxLoss: false,
    maxStakePerRate: "",
    isMaxStakePerRate: false,
  },
  tennisBookmakingSetting: {
    betDelay: "",
    isBetDelay: false,
    minStake: "",
    isMinStake: false,
    maxStake: "",
    isMaxStake: false,
    maxProfit: "",
    isMaxProfit: false,
    maxLoss: "",
    isMaxLoss: false,
    maxStakePerRate: "",
    isMaxStakePerRate: false,
  },
  soccerBookmakingSetting: {
    betDelay: "",
    isBetDelay: false,
    minStake: "",
    isMinStake: false,
    maxStake: "",
    isMaxStake: false,
    maxProfit: "",
    isMaxProfit: false,
    maxLoss: "",
    isMaxLoss: false,
    maxStakePerRate: "",
    isMaxStakePerRate: false,
  },
};

export function deepComparison(first, second) {
  /* Checking if the types and values of the two arguments are the same. */
  if (first === second) return true;

  /* Checking if any arguments are null */
  if (first === null || second === null) return false;

  /* Checking if any argument is none object */
  if (typeof first !== "object" || typeof second !== "object") return false;

  /* Using Object.getOwnPropertyNames() method to return the list of the objects’ properties */
  let first_keys = Object.getOwnPropertyNames(first);

  let second_keys = Object.getOwnPropertyNames(second);

  /* Checking if the objects' length are same*/
  if (first_keys.length !== second_keys.length) return false;

  /* Iterating through all the properties of the first object with the for of method*/
  for (let key of first_keys) {
    /* Making sure that every property in the first object also exists in second object. */
    if (!Object.hasOwn(second, key)) return false;

    /* Using the deepComparison function recursively (calling itself) and passing the values of each property into it to check if they are equal. */
    if (deepComparison(first[key], second[key]) === false) return false;
  }

  /* if no case matches, returning true */
  return true;
}
