import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  Table,
  Form,
  Col,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { Link, useLocation, useParams } from "react-router-dom";
import { apiGet, apiPut } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import AuthContext from "../../context/AuthContext";
import obj from "../../utils/helpers";
import obj2 from "../../utils/constants";
import { isArray, isEmpty, startCase } from "lodash";
import moment from "moment";
import { toast } from "wc-toast";
import ReportTabel from "./ReportTabel";
export const ParticularTab = ({ filter, setFilter, title, data, getData,parmas }) => {
  const [details, setDetails] = useState([]);
  const [expand, setExpand] = useState("");
  const showDetail = async (runnerName) => {
    if (title == "Casino") {
      setExpand(runnerName?.userTypeId);
    } else {
      setExpand(runnerName);
    }
    try {
      let api =
        title == "CasinoGamePnL"
          ? apiPath.reportAwcGamePlList
          : title == "PlayerPnL"
          ? apiPath.reportAwcPlayerPl
          : apiPath.reportAwc;

      let obj =
        title == "CasinoGamePnL"
          ? {
              ...filter,
              runnerName: runnerName,
            }
          : {
              ...filter,
              created_by: runnerName?.userTypeId,
              userType: runnerName?.userType,
            };
      const { status, data: response_users } = await apiGet(api, obj);
      if (status === 200) {
        if (response_users.success) {
          setDetails(response_users?.results);
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  const [detailsCasino, setDetailsCasino] = useState([]);
  const showDetailCasino = async (runnerName, index) => {
    try {
      let api = apiPath.reportAwc;
      let obj = {
        ...filter,
        created_by: runnerName?.userTypeId,
        userType: runnerName?.userType,
      };
      const { status, data: response_users } = await apiGet(api, obj);
      if (status === 200) {
        if (response_users.success) {
          if (index > 0) {
            setDetailsCasino((prev) => {
              return prev?.map((res) => {
                if (!isEmpty(res?.child)) {
                  if (
                    res?.userType == runnerName?.userType &&
                    res?.userTypeId == runnerName?.userTypeId
                  ) {
                    return {
                      ...res,
                      child: response_users?.results?.data,
                      expand: true,
                    };
                  } else {
                    return {
                      ...res,
                      child: recursion(
                        res?.child,
                        runnerName?.userType,
                        runnerName?.userTypeId,
                        response_users?.results?.data
                      ),
                    };
                  }
                } else if (
                  isEmpty(res?.child) &&
                  res?.userType == runnerName?.userType &&
                  res?.userTypeId == runnerName?.userTypeId
                ) {
                  return {
                    ...res,
                    child: response_users?.results?.data,
                    expand: true,
                  };
                } else {
                  return res;
                }
              });
            });
          } else {
            setDetailsCasino(
              response_users?.results?.data?.map((res) => {
                if (res?.userTypeId == runnerName?.userTypeId) {
                  return { ...res, expand: true };
                } else {
                  return res;
                }
              })
            );
          }
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const recursion = (array, type, id, result) => {
    return array?.map((res) => {
      if (res?.userType == type && res?.userTypeId == id) {
        console.log({ ...res, child: result, expand: true }, "inner recu");
        return { ...res, child: result, expand: true };
      } else {
        recursion(res?.child, type, id, result);
      }
    });
  };

  return (
    <div className="common-container">
      <Form className="bet_status bet-list-live">
        <div className="bet_outer">
          <Col lg={2} sm={3} className="mb-lg-0 mb-3">
            <div className="bet-sec bet-period">
              <Form.Label style={{ fontWeight: "600" }} className="me-2">
                Period From
              </Form.Label>
              <Form.Group className="form-group">
                <Form.Control
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      fromPeriod: e.target.value,
                      filterByDay: "",
                    })
                  }
                  max={new Date().toISOString().split("T")[0]}
                  value={filter.fromPeriod}
                  type="date"
                />
                <Form.Control
                  className="small_form_control"
                  type="text"
                  placeholder="00:00"
                  disabled
                />
              </Form.Group>
            </div>
          </Col>
          <Col lg={2} sm={3} className="mb-lg-0 mb-3">
            <div className="bet-sec bet-period">
              <Form.Label style={{ fontWeight: "600" }} className="me-2">
                Period To
              </Form.Label>
              <Form.Group className="form-group">
                <Form.Control
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      toPeriod: e.target.value,
                      filterByDay: "",
                    })
                  }
                  min={
                    filter?.fromPeriod
                      ? new Date(filter?.fromPeriod).toISOString().split("T")[0]
                      : new Date()
                  }
                  disabled={filter.fromPeriod ? false : true}
                  max={new Date().toISOString().split("T")[0]}
                  value={filter.toPeriod}
                  type="date"
                />
                <Form.Control
                  className="small_form_control"
                  type="text"
                  placeholder="23:59"
                  disabled
                />
              </Form.Group>
            </div>
          </Col>
          <div className="bet-sec bet-period">
            <Form.Label style={{ fontWeight: "600" }}>Last</Form.Label>
            <Form.Select
              className="small_select"
              onChange={(e) => setFilter({ ...filter, last: e.target.value })}
              value={filter.last}
              aria-label="Default select example"
            >
              <option value={25}>25 Txn</option>
              <option value={50}>50 Txn</option>
              <option value={100}>100 Txn</option>
              <option value={200}>200 Txn</option>
              <option value={""}>All</option>
            </Form.Select>
          </div>
          <Col lg={2} sm={3} className="mb-lg-0 mb-3">
            <div className="d-flex align-items-center">
              <Form.Label style={{ fontWeight: "600", marginRight: "5px" }}>
                User List
              </Form.Label>
              <Select
                className="basic-single"
                isClearable={true}
                placeholder={`Select User`}
                isSearchable={true}
                // onChange={(e) => {
                //   setTypeSelect(e);
                //   setSettingValues((prev) => {
                //     return { ...prev, settingTypeSecondId: e?.value };
                //   });
                // }}
                // options={list}
                // value={typeSelect}
              />
            </div>
          </Col>
        </div>
        <div className="history-btn mt-3">
          <ul className="list-unstyled mb-0">
            <li>
              <Button
                className={"me-0 theme_light_btn"}
                onClick={(e) =>
                  setFilter({
                    ...filter,
                    filterByDay: "today",
                  })
                }
              >
                Just For Today
              </Button>
            </li>
            <li>
              <Button
                className={"me-0 theme_light_btn"}
                onClick={(e) =>
                  setFilter({
                    ...filter,
                    filterByDay: "yesterday",
                  })
                }
              >
                From Yesterday
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  if (parmas == "awc") {
                    getData({}, title);
                  }
                }}
                className="theme_light_btn theme_dark_btn"
              >
                Get P/L
              </Button>
            </li>
          </ul>
        </div>
      </Form>
      <div className="account-table batting-table">
        <div className="responsive">
          <Table>
            <thead>
              <tr>
                {(title == "PlayerPnL" || title == "Casino") && (
                  <>
                    <th scope="col">UID</th>
                    <th scope="col">Player P/L</th>
                    <th scope="col">DownLine P/L</th>
                    <th scope="col">Agent Comm. P/L</th>
                    <th scope="col">Upline P/L</th>
                  </>
                )}
                {title == "CasinoGamePnL" && (
                  <>
                    <th scope="col">SportName</th>
                    <th scope="col">Player P/L</th>
                    <th scope="col">DownLine P/L</th>
                    <th scope="col">Agent Comm. P/L</th>
                    <th scope="col">Upline P/L</th>
                  </>
                )}
              </tr>
            </thead>
            {data?.data?.length > 0 ? (
              data?.data?.map((item, index) => {
                return (
                  <>
                    <tr
                      id="summary0"
                      style={{ display: "table-row" }}
                      key={index + 1}
                    >
                      {title == "CasinoGamePnL" && (
                        <>
                          {" "}
                          <td className="text-start">
                            <i
                              id={"icon_" + item?.runnerName}
                              className={
                                expand == item?.runnerName
                                  ? "fas fa-minus-square pe-2"
                                  : "fas fa-plus-square pe-2"
                              }
                              onClick={(e) => {
                                if (expand == item?.runnerName) {
                                  setExpand("");
                                  setDetails([]);
                                } else {
                                  showDetail(item?.runnerName);
                                }
                              }}
                            ></i>
                            {item?.runnerName || null}
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) !== -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) == -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span>
                              ({obj.currencyFormat(Math.abs(item?.commission))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) !== -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                        </>
                      )}

                      {title == "PlayerPnL" && (
                        <>
                          <td className="text-start">
                            {" "}
                            <a href={"#"} className="text-primary">
                              <span>{"CL"}</span>
                            </a>
                            {item?.username}
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) !== -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) == -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span>
                              ({obj.currencyFormat(Math.abs(item?.commission))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) !== -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                        </>
                      )}
                      {title == "Casino" && (
                        <>
                          <td className="text-start">
                            <i
                              id={"icon_" + item?.userTypeId}
                              className={
                                expand == item?.userTypeId
                                  ? "fas fa-minus-square pe-2"
                                  : "fas fa-plus-square pe-2"
                              }
                              onClick={(e) => {
                                if (isEmpty(expand)) {
                                  showDetailCasino(item, 0);
                                  setExpand(item?.userTypeId);
                                } else if (item?.userTypeId !== expand) {
                                  setExpand(item?.userTypeId);
                                  setDetailsCasino([]);
                                }
                              }}
                            ></i>
                            <a href={"#"} className="text-primary">
                              <span>
                                {obj2?.user_status[item?.userType || ""]}
                              </span>
                            </a>
                            {item?.username}
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) !== -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) == -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span>
                              ({obj.currencyFormat(Math.abs(item?.commission))})
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                Math.sign(item?.amount) !== -1
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({obj.currencyFormat(Math.abs(item?.amount))})
                            </span>
                          </td>
                        </>
                      )}
                    </tr>
                    {expand == item?.runnerName &&
                      title == "CasinoGamePnL" &&
                      !isEmpty(details) && (
                        <tr className="expand">
                          <td colSpan="9" className="expand_wrap">
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <th>Game Name</th>
                                  <th scope="col">Player P/L</th>
                                  <th scope="col">DownLine P/L</th>
                                  <th scope="col">Agent Comm. P/L</th>
                                  <th scope="col">Upline P/L</th>
                                </tr>
                                {details?.data?.map((res) => {
                                  return (
                                    <tr>
                                      <th
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {res?.matchName}
                                      </th>
                                      <th>
                                        {" "}
                                        <span
                                          className={
                                            Math.sign(res?.amount) !== -1
                                              ? "text-danger"
                                              : "text-success"
                                          }
                                        >
                                          (
                                          {obj.currencyFormat(
                                            Math.abs(res?.amount)
                                          )}
                                          )
                                        </span>
                                      </th>
                                      <th>
                                        {" "}
                                        <span
                                          className={
                                            Math.sign(res?.amount) == -1
                                              ? "text-danger"
                                              : "text-success"
                                          }
                                        >
                                          (
                                          {obj.currencyFormat(
                                            Math.abs(res?.amount)
                                          )}
                                          )
                                        </span>
                                      </th>
                                      <th>
                                        {" "}
                                        <span>
                                          (
                                          {obj.currencyFormat(
                                            Math.abs(res?.commission)
                                          )}
                                          )
                                        </span>
                                      </th>
                                      <th>
                                        {" "}
                                        <span
                                          className={
                                            Math.sign(res?.amount) !== -1
                                              ? "text-danger"
                                              : "text-success"
                                          }
                                        >
                                          (
                                          {obj.currencyFormat(
                                            Math.abs(res?.amount)
                                          )}
                                          )
                                        </span>
                                      </th>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}

                    {expand == item?.userTypeId &&
                      title == "Casino" &&
                      detailsCasino?.length > 0 && (
                        <tr className="expand">
                          <td
                            colSpan="9"
                            className="expand_wrap"
                            style={{ background: "lightgrey" }}
                          >
                            <table
                              style={{ width: "100%", background: "lightgrey" }}
                            >
                              <tbody style={{ background: "lightgrey" }}>
                                {detailsCasino?.map((res) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="text-start">
                                          <i
                                            id={"icon_" + res?.userTypeId}
                                            className={
                                              res?.expand
                                                ? "fas fa-minus-square pe-2"
                                                : "fas fa-plus-square pe-2"
                                            }
                                            onClick={(e) => {
                                              showDetailCasino(res, 1);
                                            }}
                                          ></i>
                                          <a
                                            href={"#"}
                                            className="text-primary"
                                          >
                                            <span>
                                              {
                                                obj2?.user_status[
                                                  res?.userType || ""
                                                ]
                                              }
                                            </span>
                                          </a>
                                          {res?.username}
                                        </td>
                                        <td>
                                          {" "}
                                          <span
                                            className={
                                              Math.sign(res?.amount) !== -1
                                                ? "text-danger"
                                                : "text-success"
                                            }
                                          >
                                            ({obj.currencyFormat(res?.amount)})
                                          </span>
                                        </td>
                                        <td>
                                          {" "}
                                          <span
                                            className={
                                              Math.sign(res?.amount) == -1
                                                ? "text-danger"
                                                : "text-success"
                                            }
                                          >
                                            ({obj.currencyFormat(res?.amount)})
                                          </span>
                                        </td>
                                        <td>
                                          {" "}
                                          <span>
                                            (
                                            {obj.currencyFormat(
                                              res?.commission
                                            )}
                                            )
                                          </span>
                                        </td>
                                        <td>
                                          {" "}
                                          <span
                                            className={
                                              Math.sign(res?.amount) !== -1
                                                ? "text-danger"
                                                : "text-success"
                                            }
                                          >
                                            ({obj.currencyFormat(res?.amount)})
                                          </span>
                                        </td>
                                      </tr>

                                      <ReportTabel
                                        res={res}
                                        showDetailCasino={showDetailCasino}
                                      />
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <span>You have no bets in this time period.</span>
                </td>
              </tr>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};
