import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import constants from "../../utils/constants";
import { useEffect } from "react";
import obj from "../../utils/constants";
import { isEmpty } from "lodash";
import helpers from "../../utils/helpers";
import { RxCross2 } from "react-icons/rx";
const FancyBetDialog = () => {
  const { name, eventid, marketId, selectionId } = useParams();
  const [matchData, setMatchData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [search_params, setSearchParams] = useState({
    eventId: eventid,
    selectionId: selectionId,
    marketId: marketId,
  });

  const getDetails = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.fancyPosition,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setDetailsData(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    getDetails();
  }, [search_params]);
  return (
    <section className="main-inner-outer bg-white h-100">
      <section className="account-table">
        <div className="container-fluid p-0 m-0">
          <div class="w-100 pop-title h-14 pl-3 top-0 sticky d-flex justify-content-between align-items-center">
            <h2 class="xl-title flex-center space-x-2" id="title">
              {name}
            </h2>
            <span
              onClick={() => window.close()}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <RxCross2 size={23} color="black" />
              Close
            </span>
          </div>
          <div className="responsive p-4">
            <h2 className="common-heading">Run Position</h2>
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgba(226, 232, 240, 1)",
                      color: "black",
                    }}
                    scope="col"
                  >
                    Run
                  </th>
                  <th
                    style={{
                      background: "rgba(226, 232, 240, 1)",
                      color: "black",
                    }}
                    scope="col"
                    className="text-end"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsData.length > 0 &&
                  detailsData.map((res, index) => {
                    return (
                      <>
                        <tr>
                          <td
                            style={
                              res.position >= 0
                                ? { background: "rgba(147, 197, 253, 1)" }
                                : { background: "rgba(252, 165, 165, 1)" }
                            }
                          >
                            {res.betRun}
                          </td>
                          <td
                            className="text-end"
                            style={
                              res.position >= 0
                                ? { background: "rgba(147, 197, 253, 1)" }
                                : { background: "rgba(252, 165, 165, 1)" }
                            }
                          >
                            {res.position >= 0
                              ? `${helpers.currencyFormat(res.position)}`
                              : `(${helpers.currencyFormat(res.position)})`}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                {isEmpty(detailsData) ? (
                  <tr>
                    <td colSpan={9}>No records found</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
    </section>
  );
};

export default FancyBetDialog;
