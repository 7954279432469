import React from "react";

const BattingIframe = ({ detail }) => {
  return (
      <iframe
        className="responsive-iframe w-100"
        style={{
          overflowX: "hidden",
          overflow: "hidden",
          // height: "510px",
          height:'180px',
          backgroundColor: "#000000",
        }}
        id="scoreId"
        // onLoad={load}
        src={detail?.scoreURL}
      ></iframe>
  );
};

export default BattingIframe;
