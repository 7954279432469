import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import { get, isEmpty, startCase } from "lodash";
import { useParams } from "react-router-dom";
import moment from "moment";
const RecentCreatedAccount = () => {
  let { user } = useContext(AuthContext);
  const parmas = useParams();
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [filter, setFilter] = useState({
    type: "3",
    betType: "casino",
    sortOrder: "asc",
    fromPeriod: previousDate,
    toPeriod: currentDate,
    casionType: "",
    last: 25,
    status: "completed",
    page: 1,
    limit: 100,
  });
  const [data, setData] = useState([]);
  const getData = async (type = "search") => {
    const { status, data: response_users } = await apiGet(
      apiPath.getBets,
      type == "search" ? filter : type
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users?.results);
      }
    }
  };

  useEffect(() => {
    if (filter?.casionType !== parmas?.type) {
      getData({ ...filter, casionType: parmas?.type });
      setFilter((prev) => {
        return { ...prev, casionType: parmas?.type };
      });
    }
  }, [parmas]);

  return (
    <div>
      <section className="main-inner-outer py-4">
        <Container fluid>
          <div className="db-sec">
            <h2 className="common-heading">Recently Created Account</h2>
          </div>
          <div className="inner-wrapper">
            <div className="common-container">
              <Form className="bet_status bet-list-live">
                <div className="bet_outer">
                  <Col lg={2} sm={3} className="mb-lg-0 mb-3">
                    <div className="bet-sec bet-period">
                      <Form.Label
                        style={{ fontWeight: "600" }}
                        className="me-2"
                      >
                        Period From
                      </Form.Label>
                      <Form.Group className="form-group">
                        <Form.Control
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              fromPeriod: e.target.value,
                              filterByDay: "",
                            })
                          }
                          max={new Date().toISOString().split("T")[0]}
                          value={filter.fromPeriod}
                          type="date"
                        />
                        <Form.Control
                          className="small_form_control"
                          type="text"
                          placeholder="00:00"
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={2} sm={3} className="mb-lg-0 mb-3">
                    <div className="bet-sec bet-period">
                      <Form.Label
                        style={{ fontWeight: "600" }}
                        className="me-2"
                      >
                        Period To
                      </Form.Label>
                      <Form.Group className="form-group">
                        <Form.Control
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              toPeriod: e.target.value,
                              filterByDay: "",
                            })
                          }
                          min={
                            filter?.fromPeriod
                              ? new Date(filter?.fromPeriod)
                                  .toISOString()
                                  .split("T")[0]
                              : new Date()
                          }
                          disabled={filter.fromPeriod ? false : true}
                          max={new Date().toISOString().split("T")[0]}
                          value={filter.toPeriod}
                          type="date"
                        />
                        <Form.Control
                          className="small_form_control"
                          type="text"
                          placeholder="23:59"
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </div>
                <div className="history-btn mt-1">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Button
                        className={"me-0 theme_light_btn"}
                        onClick={(e) =>
                          setFilter({
                            ...filter,
                            filterByDay: "today",
                          })
                        }
                      >
                        Just For Today
                      </Button>
                    </li>
                    <li>
                      <Button className="theme_light_btn theme_dark_btn">
                        Get Account
                      </Button>
                    </li>{" "}
                    <li>
                      <Button className="theme_light_btn theme_dark_btn">
                        Show Default
                      </Button>
                    </li>
                  </ul>
                </div>
              </Form>
              <div className="account-table batting-table">
                <div className="responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">User Type</th>
                        <th scope="col">UniqueId</th>
                        <th scope="col">Account Creation Date</th>
                      </tr>
                    </thead>

                    {data && data?.length > 0 ? (
                      data?.map((item) => {
                        return (
                          <tr>
                            <td>{item?.clientName || "-"}</td>
                            <td> {item?.casinoBetId}</td>
                            <td>
                              {" "}
                              {helpers.dateFormat(
                                item?.timeInserted,
                                user.timeZone
                              )}
                            </td>
                            <td>
                              {" "}
                              {(item?.ipAddress &&
                                item?.ipAddress.replace("::ffff:", "")) ||
                                "-"}
                            </td>
                            <td className="text-start">
                              <strong>{item?.casinoName}</strong>
                              <span className="angle_unicode">▸</span>
                              {item?.casinoType}{" "}
                            </td>
                            <td> {item?.platformTxId}</td>
                            <td> {item?.gameCode} </td>
                            <td> {item?.betAmount || "-"} </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no bets in this time period.</span>
                        </td>
                      </tr>
                    )}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default RecentCreatedAccount;
