import React, { useContext, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col } from "react-bootstrap";
const Heading = (props) => {
  const location = useLocation();
  const parmas = useParams();
  const current_url = location.pathname.split("/")[1];
  console.log("current_url", current_url);
  return (
    <Col sm={6}>
      <h2 className="common-heading">
        {current_url == ""
          ? "Downline List"
          : current_url == "setting"
          ? "Setting"
          : current_url == "my-account-statement"
          ? "Account Statement"
          : current_url == "my-account-summary"
          ? "Account Summary"
          : current_url == "my-profile"
          ? "Profile"
          : current_url == "my-activity-log"
          ? "Activity Log"
          : current_url == "BetListLive"
          ? "Bet List Live"
          : current_url == "RiskManagement"
          ? "Risk Management"
          : current_url == "banking"
          ? "Banking"
          : current_url == "p2p-transfer"
          ? "P2P Transfer"
          : current_url == "Betlist"
          ? "Bet list"
          : current_url == "setting"
          ? "Setting"
          : current_url == "betting-profit-loss"
          ? "Betting Profit Loss"
          : current_url == "betting-history"
          ? "Betting History"
          : current_url == "account-summary"
          ? "Account Summary"
          : current_url == "currentBets"
          ? "Current Bets" : current_url == 'transaction-history' ? 'Transaction History'
          : ""}
      </h2>
    </Col>
  );
};

export default Heading;
