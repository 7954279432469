import React from "react";
import { CSVLink } from "react-csv";
import { FiRefreshCcw } from "react-icons/fi";

const ListHeader = ({
  title,
  downloadShow,
  fileTile,
  refresh,
  csvData,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
      <h2 className="common-heading">{title}</h2>
      <div className="d-flex ">
        {downloadShow && (
          <CSVLink
            class="btn btn-highlight"
            filename={`${fileTile}.csv`}
            style={{ width: "70px", marginRight: "7px" }}
            data={csvData || []}
            target="_blank"
          >
            Download
          </CSVLink>
        )}
        <button
          onClick={() => refresh()}
          class="btn-highlight d-flex justify-content-center align-items-center"
          style={{ width: "30px" }}
        >
          <FiRefreshCcw />
        </button>
      </div>
    </div>
  );
};

export default ListHeader;
