import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
export const FilterBar = ({ filter, setFilter, getData,betType }) => {
  const location = useLocation();
console.log("betType",betType);
  return (
    <div className="bet_status bg-gray-100">
      <Row className="mb-2">
        {location?.pathname?.includes("betting-history") && (
          <Col lg={2} sm={6} xs={12} className="mb-sm-0 mb-3 d-flex p-0">
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "10px" }}
            >
              <Form.Label
                className="p-0 mb-0 label-new"
                style={{ marginRight: "10px" }}
              >
                Bet Status
              </Form.Label>
              <Form.Select
                className="small_select"
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
                value={filter.status}
                aria-label="Default select example"
              >
                 {betType=="betfair" || betType=="sportBook" ?
                <>
                 <option value="active">Matched</option>
                 <option value="completed">Settled</option>
                 <option value="voided">Cancelled</option>
                 <option value="voided">Voided</option>
                 </>
               :"" }
                {betType=="fancy" || betType=="bookmaker" || betType=="parlay" ?
                <>
                 <option value="active">Active</option>
                 <option value="voided">Voided</option>
                 <option value="completed">Settled</option>
                 
                 </>
               :"" }
                {/* <option value="unmatched">Unmatched</option> */}
               
              </Form.Select>
            </div>
          </Col>
        )}

        <Col lg={3} sm={6} xs={12} className="mb-sm-0 mb-3 d-flex">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "10px" }}
          >
            <Form.Label
              className="p-0 mb-0 label-new"
              style={{ marginRight: "10px" }}
            >
              Period
            </Form.Label>
            <Form.Group
              className="form-group d-flex"
              style={{ maxWidth: "100%" }}
            >
              <Form.Control
                className="input-new"
                type="date"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fromPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.fromPeriod}
              />{" "}
              <Form.Control
                style={{ marginLeft: "10px" }}
                className="input-new"
                type="text"
                placeholder="23:59"
                disabled
              />
            </Form.Group>
          </div>
        </Col>
        <Col lg={3} sm={6} xs={12} className="mb-sm-0 mb-3 d-flex p-0">
          <div className="d-flex align-items-center">
            <Form.Label
              className="p-0 mb-0 label-new"
              style={{ marginRight: "10px" }}
            >
              To
            </Form.Label>
            <Form.Group
              className="form-group d-flex"
              style={{ maxWidth: "100%" }}
            >
              <Form.Control
                type="date"
                className="input-new"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    toPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                min={
                  filter?.fromPeriod
                    ? new Date(filter?.fromPeriod).toISOString().split("T")[0]
                    : new Date()
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.toPeriod}
              />{" "}
              <Form.Control
                style={{ marginLeft: "10px" }}
                className="input-new"
                type="text"
                placeholder="23:59"
                disabled
              />
            </Form.Group>
          </div>
        </Col>
        <Col lg={3} sm={6} xs={12} className="mb-sm-0 mb-3 p-0">
          {/* <div className="history-btn mt-3">
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      filterByDay: "today",
                    })
                  }
                  style={{
                    // background: filter.filterByDay === "today" ? "#ffa00c" : "",
                    cursor: "pointer",
                    // color: filter.filterByDay === "today" ? "#222" : "",
                  }}
                >
                  Just For Today
                </a>
              </li>
              <li>
                <a
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      filterByDay: "yesterday",
                    })
                  }
                  style={{
                    // background: filter.filterByDay === "yesterday" ? "#ffa00c" : "",
                    cursor: "pointer",
                    // color: filter.filterByDay === "yesterday" ? "#222" : "",
                  }}
                >
                  From Yesterday
                </a>
              </li>
              <li>
                <span
                  className="btn theme_dark_btn cursor-pointer"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => getData()}
                >
                  Get History
                </span>
              </li>
              <li>
                <span
                  className="btn theme_dark_btn cursor-pointer"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // setFilter({
                    //   ...filter,
                    //   toPeriod: "",
                    //   fromPeriod: "",
                    //   filterByDay: "",
                    // });
                    getData("reset");
                  }}
                >
                  Reset
                </span>
              </li>
            </ul>
          </div> */}
          <div className="d-flex align-items-start">
            <button
              onClick={(e) =>
                setFilter({
                  ...filter,
                  filterByDay: "today",
                })
              }
              className="btn-light"
              style={{ marginRight: "7px" }}
            >
              Just For Today
            </button>{" "}
            <button
              onClick={(e) =>
                setFilter({
                  ...filter,
                  filterByDay: "yesterday",
                })
              }
              className="btn-light"
              style={{ marginRight: "7px" }}
            >
              From Yesterday
            </button>{" "}
            <button
              onClick={() => getData()}
              style={{ height: "28px" }}
              className="btn btn-highlight"
            >
              Get History
            </button>
          </div>
        </Col>
      </Row>
      {location?.pathname?.includes("betting-history") && (
        <div id="noReportMessage" class="mb-0">
          <p className="p-0 m-0">
            Betting History enables you to review the bets you have placed.{" "}
            <br />
            Specify the time period during which your bets were placed, the type
            of markets on which the bets were placed, and the sport.
          </p>
          <p className="p-0 m-0">
            Betting History is available online for the past 62 days.
          </p>
          <p className="p-0 m-0">
            User can search up to 14 days records per query only .
          </p>
        </div>
      )}
      {location?.pathname?.includes("betting-profit-loss") && (
        <div id="noReportMessage" class="mb-0">
          <p className="p-0 m-0">
            Betting Profit &amp; Loss enables you to review the bets you have
            placed. <br />
            Specify the time period during which your bets were placed, the type
            of markets on which the bets were placed, and the sport.
          </p>
          <p className="p-0 m-0">
            Betting Profit &amp; Loss is available online for the past 62 days.
          </p>
          <p className="p-0 m-0">
            User can search up to 14 days records per query only .
          </p>
        </div>
      )}
    </div>
  );
};
