import React, { useContext } from "react";
import MyAccountSidebar from "../../components/MyAccountSidebar";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import Breadcrumbs from "./Breadcrumbs";
import {
  Container,
  Col,
  Button,
  Row,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
const MyAccountSummary = () => {
  let { user_coins } = useContext(AuthContext);
  return (
    <div>
      <div className="user_data_div">
        <section className="py-4 main-inner-outer">
          <Container fluid>
            <Breadcrumbs />

            <Row>
              {/* <Col lg={3}>
              <MyAccountSidebar />
            </Col> */}
              <Col lg={12} md={12}>
                <section className="account-table">
                  <div className="responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">Your Balances</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          style={{
                            background: "rgba(226, 232, 240, 1)",
                            borderBottom: "2px solid rgba(107, 114, 128, 1)",
                          }}
                        >
                          <td
                            style={{
                              background: "rgba(226, 232, 240, 1)",
                              borderBottom: "2px solid rgba(107, 114, 128, 1)",
                            }}
                          >
                            {" "}
                            {helpers.currencyFormat(user_coins)} PBU
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </section>
                {/* <div className="inner-wrapper">
     

                <h2 className="common-heading">Account Summary</h2>

                <div className="bg-white py-2 px-3 total-balance-summary">
                  <div className="first_cols">
                  <dt>Total Balance</dt>
                  <strong>
                    {helpers.currencyFormat(user_coins)} <sub>PBU</sub>
                  </strong>
                  </div>
                </div>
                </div> */}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default MyAccountSummary;
