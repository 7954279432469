import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import Search from "./Users/Search";
const PaymentTransferLog = (props) => {
  let { user } = useContext(AuthContext);
  let user_by_created = user.id;
  let userType = user.userType;
  const [show_model, setShowModel] = useState(false);
  const [reset, setRest] = useState(false);
  const [results, setResults] = useState([]);
  const [transferAmount, setTransferAmount] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
    keyword: "",
    status: "active",
    created_by: user_by_created,
    userType: userType,
  });

  const onSubmit = (params) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        keyword: params.keyword,
        status: params.status,
      };
    });
  };

  const handlePageClick = (event) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  return (
    <div>
      <div className="user_data_div">
        <section className="main-inner-outer py-4">
          <Container fluid>
              <div className="find-member-sec search_banking_detail" style={{marginBottom:"10px"}}>
                <Search
                onSubmit={onSubmit}
                search_params={search_params}
                reset={reset} />
              </div>
              <div className="inner-wrapper">
                <div className="common-container">
                  <div className="account-table batting-table">
                    <div className="responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Account</th>
                            <th scope="col">Logs</th>
                          </tr>
                        </thead>
                        <tbody>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default PaymentTransferLog;