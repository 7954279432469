import React from "react";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";
import { FaArrowAltCircleRight } from "react-icons/fa";
import ListHeader from "./ListHeader";
import obj from "../../utils/constants";

const FancyBetTypeListing = ({ title, data, showOdds, tileFile, refresh }) => {
  const matchDetail = (updated, game) => {
    return (
      <div className="account-table match-odd-table match-odd-table-new">
        <div className="responsive">
          <Table>
            <tbody className="header-new">
              <tr>
                <td width="10%" rowSpan="2">
                  <strong   style={{ color: "white", textDecoration: "underline" }}>{game}</strong>
                </td>
                <td width="10%">
                  <strong></strong>
                </td>
                <td>
                  <strong></strong>
                </td>
                <td style={{ borderLeft: "1px solid #d1d5dbcc" }} width={"15%"} rowSpan="3" className="text-center">
                  <strong>Matched Amount</strong>
                </td>
              </tr>
            </tbody>
            <tbody className="match-tbody">
              {updated?.length > 0 &&
                updated.map((res, index) => {
                  return (
                    <>
                      <tr>
                        <td
                          style={{ borderRight: "1px solid #d1d5dbcc" }}
                          className="text-center"
                        >
                          {dayjs(res.eventDateTime).format("YYYY-MM-DD")}
                        </td>
                        <td colSpan={2} className="bg-yellow border-0 p-2">
                          <a>
                            <strong
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                color: "#2779bf",
                              }}
                            >
                              {" "}
                              {res?.matchName}
                            </strong>{" "}
                            <FaArrowAltCircleRight
                              style={{ margin: "0 3px" }}
                              size={14}
                              color="rgba(107, 114, 128, 1"
                            />
                            <span className="ms-3">{res?.fancyName} </span>
                          </a>
                        </td>
                        {/* <td className="border-0 bg-yellow">
                  <p className="text-danger">
                    {res?.positionLoseAmount
                      ? "(-" + res?.positionLoseAmount + ")"
                      : 0}
                  </p>
                </td> */}

                        {/* <td className="border-0 bg-yellow"></td> */}

                        {/* <td className="border-0 bg-yellow">
                  <p className="text-success">
                    {res?.positionProfitAmount
                      ? res?.positionProfitAmount
                      : 0}
                  </p>
                </td> */}
                        {/* <td className="border-right-0 text-center">
                  <Link
                    to={`/DownlinePnl-sport-premium/${res?.eventId}/${res?.selectionId}/${res?.marketId}/${user?.userType}/${user?._id}`}
                    target="_blank"
                    className="green-btn"
                  >
                    {"Book"}
                  </Link>
                </td> */}
                        <td
                          style={{ borderLeft: "1px solid #d1d5dbcc" }}
                          className="text-center "
                        >
                          {res?.positionLoseAmount}
                        </td>
                      </tr>

                      {/* {index + 1 == id ? (
                  <FancyListDropdown
                    showMatch={showMatch}
                    data={res?.jsonData}
                    selectData={selectData}
                  />
                ) : (
                  ""
                )} */}
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };
  return (
    <div className="risk-management-table">
      <ListHeader
        title={title}
        downloadShow={data?.length > 0 ? true : false}
        fileTile={tileFile}
        refresh={refresh}
        csvData={
          data?.length > 0
            ? [
                [
                  "Sports",
                  "Market Date",
                  "Event/Market Name",
                  "Matched Amount",
                ],
                ...data?.map(
                  ({ eventType, timeInserted, matchName, fancyName,positionLoseAmount }) => [
                    obj?.betCheckObj[eventType],
                    timeInserted,
                    `${matchName} -> ${fancyName}`,
                    positionLoseAmount,
                  ]
                ),
              ]
            : []
        }
      />
      {data?.length > 0 ? (
        <>
          {" "}
          {showOdds?.cricket &&
            matchDetail(
              data?.filter((res) => {
                return res?.eventType == "4";
              }),
              "Cricket"
            )}
          {showOdds?.tennis &&
            matchDetail(
              data?.filter((res) => {
                return res?.eventType == "2";
              }),
              "Tennis"
            )}
          {showOdds?.soccer &&
            matchDetail(
              data?.filter((res) => {
                return res?.eventType == "1";
              }),
              "Soccer"
            )}
        </>
      ) : (
        <div id="noData_MATCH_ODDS" class="bg-blueGray-200 p-4 mb-2">
          There are currently no matched bets for this markets
        </div>
      )}
      {/* )} */}
    </div>
  );
};

export default FancyBetTypeListing;
