import React, { useState, useEffect, useMemo, useContext } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { toast } from "wc-toast";
import { Link, useLocation, useParams } from "react-router-dom";
import { apiPost, apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { debounce, pick, startCase,compact } from "lodash";
import constants from "../../utils/constants";
import AuthContext from "../../context/AuthContext";
import { useForm } from "react-hook-form";

const Add = (props) => {
  const location = useLocation();
  const user_params = compact(location.pathname.split("/"));
  const [password_same, set_password_same] = useState();
  const [isLoader, setLoader] = useState(false);
  const [metaData, setMetaData] = useState();
  const onSubmit = async (request) => {
    let check = await filterArray(request?.username);
    if (check) {
      let obj;
      if (props.slug === "agent") {
        obj = pick(request, [
          // "website",
          "email",
          "username",
          "password",
          "userType",
          "firstName",
          "lastName",
          "phone",
          "timeZone",
          "commission",
          // "amount",
          "exposureLimit",
        ]);
      } else {
        obj = pick(request, [
          // "website",
          "email",
          "username",
          "password",
          "userType",
          "firstName",
          "lastName",
          "phone",
          "timeZone",
        ]);
      }
      setLoader(true);
      set_password_same(true);

      if (request.password !== request.confirmPassword) {
        set_password_same(false);
      } else {
        /**
         * ! request.userType = "super_admin";
         * * This should be managed by Backend.
         */
        try {
          const { status, data: response_users } = await apiPost(
            apiPath.addProfile,
            { ...obj, createdBy: props.id }
          );
          if (status === 200) {
            if (response_users.success) {
              setLoader(false);
              props.setShowModel();
              props.refreshUsers();
              toast.success(response_users.message);
              reset();
            } else {
              setLoader(false);
              toast.error(response_users.message);
            }
          } else {
            setLoader(false);
            toast.error(response_users.message);
          }
        } catch (err) {
          setLoader(false);
          toast.error(err.response.data.message);
        }
      }
    }
  };

  const getMetaData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.metaData);
    if (status === 200) {
      if (response_users.success) {
        setMetaData(response_users.results);
      }
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const filterArray = async (value) => {
    const { status, data: response_users } = await apiPost(
      apiPath.checkProfile,
      {
        username: value,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        clearErrors("username");
        return true;
      } else {
        setError("username", {
          required: true,
          message: "Username Already Exist!",
        });
        return false;
      }
    }
  };
  function handleChange(event) {
    debounceFn(event);
  }
  const debounceFn = useMemo(() => debounce(filterArray, 1000), []);
  const { user } = useContext(AuthContext);
  console.log(
    user?.userType == "super_agent" || props?.slug == "agent",
    "user"
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      timeZone: "Asia/Kolkata",
      commission:
        user?.userType == "super_agent" || props?.slug == "agent" ? 2 : 0,
      exposureLimit:
        user?.userType == "super_agent" || props?.slug == "agent" ? "" : 1000,
    },
  });
  return (
    <div>
      <Modal
        show={true}
        onHide={() => {
          props.setShowModel();
        }}
        className="super-admin-modal "
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="modal-title-status">
            Add{" "}
            {/* {startCase(
              constants.user_next_status[props.slug] == "user"
                ? "Player"
                : constants.layerPanel[props.slug]
            )} */}
             {startCase(
                  user_params[1] !== "agent" &&
                    user?.userType !== "agent"
                    ? constants.layerPanel1[
                        user_params[1] !== undefined &&
                        user_params[0] !== ""
                          ? user_params[1]
                          : user?.userType
                      ]
                    : "Player"
                )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <div className="useradd-h">STEP 1</div>
          <Form className="super-admin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="user-add-form">
              <Row className="user-add-form-row">
                <>
                  <h6>Personal Information</h6>
                  {/* {constants.user_next_status[props.slug] == "super_admin" && (
                    <Col md={6} className="mb-2 mb-md-3">
                      <Form.Group className="row">
                        <Col md={4}>
                          <Form.Label>
                            Select Website{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Select
                            aria-label="Default select example"
                            className={errors.website ? " is-invalid " : ""}
                            {...register("website", {
                              required: "Please select website",
                            })}
                          >
                            <option value="">Please select webiste</option>
                            {metaData &&
                              metaData["websiteList"] &&
                              metaData["websiteList"].map((item, key) => (
                                <option value={item.domain} key={key}>
                                  {item.domain}
                                </option>
                              ))}
                          </Form.Select>

                          {errors.website && errors.website.message && (
                            <label className="invalid-feedback text-left">
                              {errors.website.message}
                            </label>
                          )}
                        </Col>
                      </Form.Group>
                    </Col>
                  )} */}
                  <Col sm={6} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>
                          Email <span className="text-danger">*</span>
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          className={errors.email ? " is-invalid " : ""}
                          {...register("email", {
                            required: "Please enter email",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address.",
                            },
                          })}
                        />
                        {errors.email && errors.email.message && (
                          <label className="invalid-feedback text-left">
                            {errors.email.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mb-2 mb-md-3">
                    <Form.Group className="row d-flex">
                      <Col md={4}>
                        <Form.Label>
                          Username<span className="text-danger">*</span>
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter username"
                          autoComplete={false}
                          className={errors.username ? " is-invalid " : ""}
                          {...register("username", {
                            required: "Please enter username",
                            minLength: {
                              value: 2,
                              message:
                                "Username should contain at least 2 characters.",
                            },
                            maxLength: {
                              value: 30,
                              message:
                                "Username should contain at least 30 characters.",
                            },
                            onChange: (value) => {
                              if (value?.target?.value?.length > 3) {
                                handleChange(value?.target?.value);
                              }
                            },
                          })}
                        />
                        {errors.username && errors.username.message && (
                          <label className="invalid-feedback text-left">
                            {errors.username.message}
                          </label>
                        )}
                      </Col>
                      {/* <Col md={2}>
                    <Button className="theme_dark_btn">Check</Button>
                  </Col> */}
                    </Form.Group>
                  </Col>

                  <Col sm={6} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>
                          Password<span className="text-danger">*</span>
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          className={errors.password ? " is-invalid " : ""}
                          {...register("password", {
                            required: "Please enter password",
                            minLength: {
                              value: 8,
                              message:
                                "Password should contain atleast 8 characters",
                            },
                            maxLength: {
                              value: 16,
                              message:
                                "Password should contain maximum 16 characters",
                            },
                            // pattern: {
                            //   value:
                            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            //   message:
                            //     "Your password should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character",
                            // },
                          })}
                        />
                        {errors.password && errors.password.message && (
                          <label className="invalid-feedback text-left">
                            {errors.password.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col sm={6} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="password"
                          placeholder="Confirm Password"
                          className={
                            errors.confirmPassword ? " is-invalid " : ""
                          }
                          {...register("confirmPassword", {
                            required: "Please enter confirm password",
                          })}
                        />
                        {errors.confirmPassword &&
                          errors.confirmPassword.message && (
                            <label className="invalid-feedback text-left">
                              {errors.confirmPassword.message}
                            </label>
                          )}
                        {password_same === false &&
                          errors.confirmPassword !== "" && (
                            <label className="invalid-feedback text-left">
                              Password does not match.
                            </label>
                          )}
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col sm={6} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>First Name</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter FirstName"
                          className={errors.firstName ? " is-invalid " : ""}
                          {...register("firstName")}
                        />
                        {errors.firstName && errors.firstName.message && (
                          <label className="invalid-feedback text-left">
                            {errors.firstName.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col sm={6} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>Last Name</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter LastName"
                          className={errors.lastName ? " is-invalid " : ""}
                          {...register("lastName")}
                        />
                        {errors.lastName && errors.lastName.message && (
                          <label className="invalid-feedback text-left">
                            {errors.lastName.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={4}>
                        <Form.Label>Phone</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone Number"
                          className={errors.phone ? " is-invalid " : ""}
                          {...register("phone")}
                        />
                        {errors.phone && errors.phone.message && (
                          <label className="invalid-feedback text-left">
                            {errors.phone.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* <Col sm={6} className="mb-2 mb-md-3">
                <Form.Group className="row">
                  <Col md={4}>
                    <Form.Label>Select Time Zone</Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Select
                      aria-label="Default select example"
                      className={errors.timeZone ? " is-invalid " : ""}
                      {...register("timeZone", {
                        required: "Please select time zone",
                      })}
                    >
                      {metaData &&
                        metaData["timeZoneList"] &&
                        metaData["timeZoneList"].map((item, key) => (
                          <option value={item.tz} key={key}>
                            {item.tz}
                          </option>
                        ))}
                    </Form.Select>
                    {errors.timeZone && errors.timeZone.message && (
                      <label className="invalid-feedback text-left">
                        {errors.timeZone.message}
                      </label>
                    )}
                  </Col>
                </Form.Group>
              </Col> */}
                  {props.slug === "agent" && (
                    <>
                      <Col sm={6} className="mb-2 mb-md-3">
                        <Form.Group className="row">
                          <Col md={4}>
                            <Form.Label>Commission<span className="text-danger">*</span></Form.Label>
                          </Col>
                          <Col md={8}>
                            <Form.Control
                              type="number"
                              // disabled={
                              //   user?.userType == "super_agent" ||
                              //   props?.slug == "agent"
                              //     ? true
                              //     : false
                              // }
                              min="0"
                              max="5"
                              placeholder="Enter Commission"
                              className={
                                errors.commission ? " is-invalid " : ""
                              }
                              {...register("commission", {
                                required: "Please enter Commission",
                              })}
                            />
                            {errors.commission && errors.commission.message && (
                              <label className="invalid-feedback text-left">
                                {errors.commission.message}
                              </label>
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="mb-2 mb-md-3">
                        <Form.Group className="row">
                          <Col md={4}>
                            <Form.Label>
                              Exposure{" "}
                              {(user?.userType == "super_agent" ||
                                props?.slug == "agent") && (
                                  <span className="text-danger">*</span>
                                )}
                            </Form.Label>
                          </Col>
                          <Col md={8}>
                            <Form.Control
                              type="number"
                              placeholder="Enter Exposure Limit"
                              className={
                                errors.exposureLimit ? " is-invalid " : ""
                              }
                              {...register("exposureLimit", {
                                required: "Please enter Exposure Limit",
                              })}
                            />
                            {errors.exposureLimit &&
                              errors.exposureLimit.message && (
                                <label className="invalid-feedback text-left">
                                  {errors.exposureLimit.message}
                                </label>
                              )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </>
              </Row>
            </div>
            <Form.Group className="mt-3 useraddbtn">
              <Button
                type="submit"
                disabled={isLoader ? true : false}
                className="theme_dark_btn px-3 "
              >
                Create
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Add;
