import React, { useState,useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import constants from "../../utils/constants";
const ShowBetfair = () => {
  const [isLoader, setLoader] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const params = useParams();

  const [search_params, setSearchParams] = useState({
    eventId: params.eventId,
    marketId: params.marketId,
   
  });
console.log("params.eventId",params.eventId);
  const getMatchData = async () => {
    setLoader(true);

    const { status, data: response_users } = await apiGet(
      apiPath.riskBetfair+`?eventId=${params?.eventId}&marketId=${params?.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setLoader(false);
          setMatchData(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    getMatchData();
  }, []);
  useEffect(() => {
    const intervalID = setInterval(() => {
      getMatchData();
    
    }, 10 * 1000);
    return () => clearInterval(intervalID);
  }, [params?.eventId,params?.marketId ]);
  console.log(matchData);
  return (
    <div class="pop-wrap bg-white d-flex flex-column">
      <div class="w-100 pop-title h-14 pl-3 top-0 sticky d-flex justify-content-between align-items-center">
        <h2 class="xl-title flex-center space-x-2" id="title">
          Cricket
        </h2>
        <span
          onClick={() => window.close()}
          style={{ cursor: "pointer" }}
          className="d-flex align-items-center"
        >
          <RxCross2 size={23} color="black" />
          Close
        </span>
      </div>

      <div class="overflow-y-auto w-100 pop-wrap-inner px-3 pt-3">
        <div class="title-dark p-2 d-flex align-items-center space-x-1 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            ></path>
          </svg>
          <span id="marketTime" style={{ marginLeft: "5px" }}>
            2024-09-16
          </span>
        </div>

        <table class="mb-4 w-100 border-b">
          <thead class="title-lightdark">
            <tr>
              <th class="border-r" rowspan="2">
                Event name
              </th>
              <th class="border-r" rowspan="2">
                Selection name
              </th>
              <th class="border-r text-center bg-blue-300" colspan="2">
                Back
              </th>
              <th class="text-center bg-red-300" colspan="2">
                Lay
              </th>
            </tr>
            <tr>
              <th class="bg-blue-300 border-r">Avg. odds</th>
              <th class="bg-blue-300 border-r">Stake</th>
              <th class="bg-red-300 border-r">Avg. odds</th>
              <th class="bg-red-300 border-r">Stake</th>
            </tr>
          </thead>

          <tbody id="content">
          {Object.keys(matchData).length !== 0  && matchData?.jsonData.length>0 &&  matchData?.jsonData?.map((res,index) => {
            return(
            <tr id="tempTr" className="tempTr">
              <td class="align-L">
              {index<1 &&
                  <>
                <a
                  href="javascript:void(0);"
                  id="marketPath"
                  class="link-default"
                >
                 
                  <strong id="eventName"> {matchData?.eventName}</strong>
                  <svg
                    class="w-4 w-4 text-gray-500 mx-1 inline-flex items-center"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span id="marketName">Match Odds</span>
                </a>
                </>
                }
              </td>
              <td class="align-L" id="selectionName">
                {res?.RunnerName}
              </td>
              <td id="backAvgOdds"> {res?.averageOddsBack}</td>
              <td>
                <a
                  href="javascript:void(0);"
                  id="backStake"
                  class="link-default"
                >
                   {res?.averageBetAmountBack}
                </a>
              </td>
              <td id="layAvgOdds" style={{ display: "table-cell" }}>
              {res?.averageOddsLay}
              </td>
              <td style={{ display: "table-cell" }}>
                <a
                  href="javascript:void(0);"
                  class="text-red-600"
                  id="layStake"
                >
                    {res?.averageBetAmountLay}
                </a>
              </td>
              
            </tr>)
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShowBetfair;
