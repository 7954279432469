import React, { useContext, useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import AuthContext from "../context/AuthContext";
import Select from "react-select";
import { isEmpty, startCase } from "lodash";
import obj from "../utils/constants";
import {
  cricketBookmakingSetting,
  deepComparison,
  limitSetting,
  marketSetting,
  sessionSetting,
  soccerBookmakingSetting,
  tennisBookmakingSetting,
  valuesSetting,
} from "../utils/constantList";
import { toast } from "wc-toast";
const Setting = () => {
  const { user } = useContext(AuthContext);
  const [select1, setSelect1] = useState("default");
  const [select2, setSelect2] = useState("default");
  const [typeSelect, setTypeSelect] = useState([]);
  const [typeSelect2, setTypeSelect2] = useState([]);
  const [values, setValues] = useState(valuesSetting);
  const [playType, setPlayType] = useState("all");
  const [list, setList] = useState([]);
  const [listCompeition, setListCompeition] = useState([]);
  const [listEvents, setListEvents] = useState([]);
  const [listMarket, setListMarket] = useState([]);
  const [settingList, setSettingList] = useState([]);
  const [settingValues, setSettingValues] = useState({
    settingTypeFirst: "default",
    settingTypeFirstId: "",
    settingTypeSecond: "default",
    settingTypeSecondId: "",
  });

  const getData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.userList);
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.length > 0) {
          setList(
            response_users?.results?.map((res) => {
              return { label: res?.username, value: res?._id };
            })
          );
        }
      }
    }
  };

  const getDataSetting = async () => {
    const { status, data: response_users } = await apiGet(apiPath.settingList);
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.length > 0) {
          let obj = response_users?.results?.find((res) => {
            return (
              res?.settingTypeFirst === settingValues?.settingTypeFirst &&
              res?.settingTypeFirstId === settingValues?.settingTypeFirstId &&
              res?.settingTypeSecond === settingValues?.settingTypeSecond &&
              res?.settingTypeSecondId === settingValues?.settingTypeSecondId
            );
          });
          if (!isEmpty(obj)) {
            setValues(obj);
          }
          setSettingList(response_users?.results);
        }
      }
    }
  };

  const getCompetion = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.matchFilterList
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.length > 0) {
          setListCompeition(
            response_users?.results
              ?.filter((res, index, self) => {
                // Filter out duplicate seriesIds
                return (
                  index === self.findIndex((r) => r.seriesId === res.seriesId)
                );
              })
              ?.map((res) => {
                return {
                  label: `${startCase(res?.gameType)} > ${startCase(
                    res?.seriesName
                  )}`,
                  value: res,
                };
              })
          );
          setListEvents(
            response_users?.results?.map((res) => {
              return {
                label: `${startCase(res?.gameType)} > ${startCase(
                  res?.eventName
                )}`,
                value: res,
              };
            })
          );
          setListMarket(
            response_users?.results?.map((res) => {
              return {
                label: `${startCase(res?.gameType)} > ${startCase(
                  res?.eventName
                )} > ${startCase(res?.market)}`,
                value: res,
              };
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    getData();
    getCompetion();
    getDataSetting();
  }, []);

  const ObjSelect = {
    sport: obj.betCheckDataLabel2,
    competition: listCompeition,
    event: listEvents,
    market: listMarket,
  };

  const handelChange1 = (value) => {
    setSettingValues((prev) => {
      return { ...prev, settingTypeFirst: value, settingTypeFirstId: "" };
    });
    setPlayType("all");
    setSelect1(value);
    setTypeSelect2([]);
    setValues(valuesSetting);
  };

  const handelChange2 = (value) => {
    setSettingValues((prev) => {
      return { ...prev, settingTypeSecond: value, settingTypeSecondId: "" };
    });
    setSelect2(value);
    setTypeSelect([]);
    setValues(valuesSetting);
  };

  const handelChangeTypeSelect2 = (e, select) => {
    setTypeSelect2(e);
    if (["tennis", "soccer", "cricket"].includes(e?.value)) {
      setPlayType(e?.value);
      setSettingValues((prev) => {
        handelChangeSetting({ ...prev, settingTypeFirstId: e?.value });
        return { ...prev, settingTypeFirstId: e?.value };
      });
    } else {
      setPlayType(e?.value?.gameType);
      setSettingValues((prev) => {
        let obj = {
          ...prev,
          settingTypeFirstId:
            select == "market"
              ? e?.value?.marketId
              : select == "competition"
              ? e?.value?.seriesId
              : e?.value?.eventId,
        };
        handelChangeSetting(obj);
        return obj;
      });
    }
    setValues(valuesSetting);
  };

  const onChangeValues = (obj, key, value) => {
    setValues((prev) => {
      return {
        ...prev,
        [obj]: {
          ...prev[obj],
          [key]: value,
        },
      };
    });
  };

  const isValid = (obj) => {
    if (obj?.settingTypeFirst !== "default") {
      if (isEmpty(obj?.settingTypeFirstId)) {
        toast.error(`Please Select ${startCase(select1)} wise`);
        return false;
      }
    }

    if (obj?.settingTypeSecond !== "default") {
      if (isEmpty(obj?.settingTypeSecondId)) {
        toast.error(`Please Select ${startCase(select2)} wise`);
        return false;
      }
    }

    if (playType !== "all") {
      if (
        deepComparison(obj?.array, {
          limitSetting: valuesSetting.limitSetting,
          marketSetting: valuesSetting.marketSetting,
          sessionSetting: valuesSetting.sessionSetting,
          ...(playType == "cricket" && {
            cricketBookmakingSetting: valuesSetting.cricketBookmakingSetting,
          }),
          ...(playType == "soccer" && {
            soccerBookmakingSetting: valuesSetting.soccerBookmakingSetting,
          }),
          ...(playType == "tennis" && {
            tennisBookmakingSetting: valuesSetting.tennisBookmakingSetting,
          }),
        })
      ) {
        toast.error("Pleae enter some input to fields");
        return false;
      }
    } else {
      if (deepComparison(obj?.array, valuesSetting)) {
        toast.error("Pleae enter some input to fields");
        return false;
      }
    }

    return true;
  };

  const onSubmit = async (playType) => {
    let array = values;
    if (playType !== "all") {
      array = {
        limitSetting: values.limitSetting,
        marketSetting: values.marketSetting,
        sessionSetting: values.sessionSetting,
        ...(playType == "cricket" && {
          cricketBookmakingSetting: values.cricketBookmakingSetting,
        }),
        ...(playType == "soccer" && {
          soccerBookmakingSetting: values.soccerBookmakingSetting,
        }),
        ...(playType == "tennis" && {
          tennisBookmakingSetting: values.tennisBookmakingSetting,
        }),
      };
    }
    let valid = isValid({ ...settingValues, array: array });
    if (valid) {
      const { status, data: response_users } = await apiPost(
        apiPath.submitSetting,
        {
          ...settingValues,
          array: array,
        }
      );
      if (status === 200) {
        if (response_users.success) {
          getDataSetting();
        }
      }
    }
  };

  const reset = () => {
    setSelect1("default");
    setSelect2("default");
    setTypeSelect([]);
    setTypeSelect2([]);
    setValues(valuesSetting);
    setPlayType("all");
    setSettingValues({
      settingTypeFirst: "default",
      settingTypeFirstId: "",
      settingTypeSecond: "default",
      settingTypeSecondId: "",
    });
    handelChangeSetting({
      settingTypeFirst: "default",
      settingTypeFirstId: "",
      settingTypeSecond: "default",
      settingTypeSecondId: "",
    });
  };

  const handelChangeSetting = (obj) => {
    let objNew = settingList?.find((res) => {
      return (
        res?.settingTypeFirst === obj?.settingTypeFirst &&
        res?.settingTypeFirstId === obj?.settingTypeFirstId &&
        res?.settingTypeSecond === obj?.settingTypeSecond &&
        res?.settingTypeSecondId === obj?.settingTypeSecondId
      );
    });
    if (!isEmpty(objNew)) {
      setValues(objNew);
    }
  };

  return (
    <div>
      <section className="main-inner-outer py-4">
        <Container fluid>
          <div className="db-sec">
            <h6 className="common-heading">Settings</h6>
          </div>
          <div className="setting_dashboard">
            <div className="setting_dashboard_block">
              <ul class="input-list diaplayflex">
                <li>
                  <div
                    onClick={() => {
                      handelChange1("default");
                    }}
                  >
                    {" "}
                    <input
                      autocomplete="off"
                      type="radio"
                      name="typeWise"
                      checked={select1 == "default"}
                    />
                    <label for="typeWiseDefault">Default</label>
                  </div>

                  <div
                    onClick={() => {
                      handelChange1("sport");
                    }}
                  >
                    {" "}
                    <input
                      autocomplete="off"
                      type="radio"
                      name="typeWise"
                      checked={select1 == "sport"}
                    />
                    <label for="sport">Sport Wise</label>
                  </div>

                  <div
                    onClick={() => {
                      handelChange1("competition");
                    }}
                  >
                    {" "}
                    <input
                      autocomplete="off"
                      type="radio"
                      name="typeWise"
                      checked={select1 == "competition"}
                    />
                    <label for="competition">Competition Wise</label>
                  </div>

                  <div
                    onClick={() => {
                      handelChange1("event");
                    }}
                  >
                    {" "}
                    <input
                      autocomplete="off"
                      type="radio"
                      name="typeWise"
                      checked={select1 == "event"}
                    />
                    <label for="event">Event Wise</label>
                  </div>

                  <div
                    onClick={() => {
                      handelChange1("market");
                    }}
                  >
                    {" "}
                    <input
                      autocomplete="off"
                      type="radio"
                      name="typeWise"
                      checked={select1 == "market"}
                    />
                    <label for="market">Market Wise</label>
                  </div>
                </li>
              </ul>
              <ul class="input-list diaplayflex">
                <li>
                  <div onClick={() => handelChange2("default")}>
                    <input
                      autocomplete="off"
                      type="radio"
                      name="usersWise"
                      checked={select2 == "default"}
                    />
                    <label>Default</label>
                  </div>

                  {user?.userType == "owner" && (
                    <div onClick={() => handelChange2("super_admin")}>
                      {" "}
                      <input
                        autocomplete="off"
                        type="radio"
                        name="usersWise"
                        checked={select2 == "super_admin"}
                      />
                      <label>Super Admin Wise</label>
                    </div>
                  )}

                  {user?.userType == "super_admin" && (
                    <div onClick={() => handelChange2("admin")}>
                      {" "}
                      <input
                        autocomplete="off"
                        type="radio"
                        name="usersWise"
                        checked={select2 == "admin"}
                      />
                      <label>Admin Wise</label>
                    </div>
                  )}

                  {user?.userType == "admin" && (
                    <div onClick={() => handelChange2("sub_admin")}>
                      {" "}
                      <input
                        autocomplete="off"
                        type="radio"
                        name="usersWise"
                        checked={select2 == "sub_admin"}
                      />
                      <label>Sub Admin Wise</label>
                    </div>
                  )}
                  {user?.userType == "sub_admin" && (
                    <div onClick={() => handelChange2("super_agent")}>
                      <input
                        autocomplete="off"
                        type="radio"
                        name="usersWise"
                        checked={select2 == "super_agent"}
                      />
                      <label>Super Agent Wise</label>
                    </div>
                  )}
                  {user?.userType == "super_agent" && (
                    <div onClick={() => handelChange2("agent")}>
                      <input
                        autocomplete="off"
                        type="radio"
                        name="usersWise"
                        checked={select2 == "agent"}
                      />
                      <label>Agent Wise</label>
                    </div>
                  )}
                  {user?.userType == "agent" && (
                    <div onClick={() => handelChange2("user")}>
                      <input
                        autocomplete="off"
                        type="radio"
                        name="usersWise"
                        checked={select2 == "user"}
                      />
                      <label>User Wise</label>
                    </div>
                  )}
                </li>
              </ul>
              <div className="d-flex">
                {select2 !== "default" && (
                  <div className="w-25" style={{ marginRight: "10px" }}>
                    <Select
                      className="basic-single"
                      isClearable={true}
                      placeholder={`Select ${startCase(select2)}`}
                      isSearchable={true}
                      onChange={(e) => {
                        setTypeSelect(e);
                        setSettingValues((prev) => {
                          handelChangeSetting({
                            ...prev,
                            settingTypeSecondId: e?.value,
                          });
                          return { ...prev, settingTypeSecondId: e?.value };
                        });
                      }}
                      options={list}
                      value={typeSelect}
                    />
                  </div>
                )}
                {select1 !== "default" && (
                  <div className="w-25">
                    <Select
                      className="basic-single"
                      placeholder={`Select ${startCase(select1)}`}
                      isClearable={true}
                      isSearchable={true}
                      onChange={(e) => {
                        handelChangeTypeSelect2(e, select1);
                      }}
                      options={ObjSelect[select1]}
                      value={typeSelect2}
                    />
                  </div>
                )}
              </div>
            </div>
            <div class="container settings-container">
              <div className="settings-container-inner">
                <h6>Limit Setting</h6>
                <div class="grid">
                  {limitSetting?.map((res) => {
                    return (
                      <div class="input-group-list">
                        <label>{res?.label}</label>
                        <div className="d-flex">
                          <input
                            autocomplete="off"
                            type="number"
                            value={values?.limitSetting[res?.key]}
                            placeholder={res?.placeholder}
                            style={{ marginRight: "5px" }}
                            onChange={(e) => {
                              onChangeValues(
                                "limitSetting",
                                res?.key,
                                e.target.value
                              );
                            }}
                          />
                          <input
                            checked={values?.limitSetting[res?.keyCheckbox]}
                            type="checkbox"
                            onClick={() => {
                              onChangeValues(
                                "limitSetting",
                                res?.keyCheckbox,
                                values?.limitSetting[res?.keyCheckbox]
                                  ? false
                                  : true
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="settings-container-inner">
                <h6>Market Setting</h6>
                <div class="grid">
                  {marketSetting?.map((res) => {
                    return (
                      <div class="input-group-list">
                        <label>{res?.label}</label>
                        <div className="d-flex">
                          <input
                            autocomplete="off"
                            type="number"
                            value={values?.marketSetting[res?.key]}
                            placeholder={res?.placeholder}
                            style={{ marginRight: "5px" }}
                            onChange={(e) => {
                              onChangeValues(
                                "marketSetting",
                                res?.key,
                                e.target.value
                              );
                            }}
                          />
                          <input
                            checked={values?.marketSetting[res?.keyCheckbox]}
                            type="checkbox"
                            onClick={() => {
                              onChangeValues(
                                "marketSetting",
                                res?.keyCheckbox,
                                values?.marketSetting[res?.keyCheckbox]
                                  ? false
                                  : true
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="settings-container-inner">
                <h6>Session Setting</h6>
                <div class="grid">
                  {sessionSetting?.map((res) => {
                    return (
                      <div class="input-group-list">
                        <label>{res?.label}</label>
                        <div className="d-flex">
                          <input
                            autocomplete="off"
                            type="number"
                            value={values?.sessionSetting[res?.key]}
                            placeholder={res?.placeholder}
                            style={{ marginRight: "5px" }}
                            onChange={(e) => {
                              onChangeValues(
                                "sessionSetting",
                                res?.key,
                                e.target.value
                              );
                            }}
                          />
                          <input
                            checked={values?.sessionSetting[res?.keyCheckbox]}
                            type="checkbox"
                            onClick={() => {
                              onChangeValues(
                                "sessionSetting",
                                res?.keyCheckbox,
                                values?.sessionSetting[res?.keyCheckbox]
                                  ? false
                                  : true
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div class="container settings-container">
              {(playType == "all" || playType == "cricket") && (
                <div className="settings-container-inner">
                  <h6>Cricket Bookmaking Setting</h6>
                  <div class="grid">
                    {cricketBookmakingSetting?.map((res) => {
                      return (
                        <div class="input-group-list">
                          <label>{res?.label}</label>
                          <div className="d-flex">
                            <input
                              autocomplete="off"
                              type="number"
                              value={values?.cricketBookmakingSetting[res?.key]}
                              placeholder={res?.placeholder}
                              style={{ marginRight: "5px" }}
                              onChange={(e) => {
                                onChangeValues(
                                  "cricketBookmakingSetting",
                                  res?.key,
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              checked={
                                values?.cricketBookmakingSetting[
                                  res?.keyCheckbox
                                ]
                              }
                              type="checkbox"
                              onClick={() => {
                                onChangeValues(
                                  "cricketBookmakingSetting",
                                  res?.keyCheckbox,
                                  values?.cricketBookmakingSetting[
                                    res?.keyCheckbox
                                  ]
                                    ? false
                                    : true
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {(playType == "all" || playType == "soccer") && (
                <div className="settings-container-inner">
                  <h6>Soccer Bookmaking Setting</h6>
                  <div class="grid">
                    {soccerBookmakingSetting?.map((res) => {
                      return (
                        <div class="input-group-list">
                          <label>{res?.label}</label>
                          <div className="d-flex">
                            <input
                              autocomplete="off"
                              type="number"
                              value={values?.soccerBookmakingSetting[res?.key]}
                              placeholder={res?.placeholder}
                              style={{ marginRight: "5px" }}
                              onChange={(e) => {
                                onChangeValues(
                                  "soccerBookmakingSetting",
                                  res?.key,
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              checked={
                                values?.soccerBookmakingSetting[
                                  res?.keyCheckbox
                                ]
                              }
                              type="checkbox"
                              onClick={() => {
                                onChangeValues(
                                  "soccerBookmakingSetting",
                                  res?.keyCheckbox,
                                  values?.soccerBookmakingSetting[
                                    res?.keyCheckbox
                                  ]
                                    ? false
                                    : true
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {(playType == "all" || playType == "tennis") && (
                <div className="settings-container-inner">
                  <h6>Tennis Bookmaking Setting</h6>
                  <div class="grid">
                    {tennisBookmakingSetting?.map((res) => {
                      return (
                        <div class="input-group-list">
                          <label>{res?.label}</label>
                          <div className="d-flex">
                            <input
                              autocomplete="off"
                              type="number"
                              value={values?.tennisBookmakingSetting[res?.key]}
                              placeholder={res?.placeholder}
                              style={{ marginRight: "5px" }}
                              onChange={(e) => {
                                onChangeValues(
                                  "tennisBookmakingSetting",
                                  res?.key,
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              checked={
                                values?.tennisBookmakingSetting[
                                  res?.keyCheckbox
                                ]
                              }
                              type="checkbox"
                              onClick={() => {
                                onChangeValues(
                                  "tennisBookmakingSetting",
                                  res?.keyCheckbox,
                                  values?.tennisBookmakingSetting[
                                    res?.keyCheckbox
                                  ]
                                    ? false
                                    : true
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => onSubmit(playType)}
              className="theme_dark_btn"
              style={{ marginRight: "10px" }}
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                reset();
              }}
              className="theme_light_btn"
            >
              Cancel
            </Button>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Setting;
