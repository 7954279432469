import React from "react";
import useWindowDimensions from "./getWindowSize";

const Odds = ({
  data,
  backOdds,
  layOdds,
  type,
  active,
  setActive,
  activeInner,
  minMax,
}) => {
  const { width } = useWindowDimensions();
  return (
    <div class="main-analysis mb-2">
      <div class="top">
        <div class="toptitle d-inline">
          {type == "Match Odds" ? `${data.length} Selection - ${type}` : type}
        </div>
        <div class="min-max d-inline ">
          <span>
            {width > 767 && (
              <span class="desktop-minmax">
                Min: {minMax?.minAmount || 100} | Max:{" "}
                {minMax?.maxAmount || 100000}
              </span>
            )}
            <span
              class="f-right"
              style={{ paddingLeft: "5px", cursor: "pointer" }}
            >
              {active ? (
                <i
                  onClick={() =>
                    setActive((prev) => {
                      return {
                        ...prev,
                        [activeInner]: !prev[activeInner],
                      };
                    })
                  }
                  class="fa fa-minus"
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  onClick={() =>
                    setActive((prev) => {
                      return {
                        ...prev,
                        [activeInner]: !prev[activeInner],
                      };
                    })
                  }
                  class="fa fa-plus"
                  aria-hidden="true"
                ></i>
              )}
            </span>
          </span>
        </div>
      </div>
      {active && (
        <div class="table-responsive analysis-running-market bookmakerbs">
          <table class="w-100 bets">
            <tbody style={{ borderLeft: "white", borderRight: "white" }}>
              <tr class="bet-all-new">
                <td class="w-55 mob-minmax">
                  <span
                    class="desktop-minmax text-gray"
                    style={{ paddingLeft: "3px" }}
                  >
                    Min: {minMax?.minAmount || 100} | Max:{" "}
                    {minMax?.maxAmount || 100000}
                  </span>
                </td>
                <td
                  class={
                    activeInner == "toss"
                      ? "w-90 text-center"
                      : "w-45 text-center"
                  }
                >
                  <div>
                    <div
                      class={
                        activeInner == "toss"
                          ? "w-95 float-left"
                          : "w-50 float-left"
                      }
                    >
                      {width > 767 && activeInner !== "toss" && (
                        <>
                          <a
                            id="btnBack"
                            class="bid  btn-back mobile-hide bg-trans lh22"
                            side="Back"
                          ></a>
                          <a
                            id="btnBack"
                            class="bid  btn-back mobile-hide bg-trans lh22"
                            side="Back"
                          ></a>
                        </>
                      )}
                      <a
                        id="backAll"
                        class={
                          activeInner == "toss"
                            ? "bid-toss bid back-all lh22 bid1"
                            : "bid back-all lh22 bid1"
                        }
                      >
                        <span class="f11">Back</span>
                      </a>
                    </div>
                    {activeInner !== "toss" && (
                      <div class="w-50 float-left">
                        <a id="layAll" class="ask  lay-all lh22 ask1">
                          <span class="f11">Lay</span>
                        </a>
                        {width > 767 && (
                          <>
                            <a
                              id="btnBack"
                              class="ask btn-lay mobile-hide bg-trans lh22"
                              side="lay"
                            ></a>
                            <a
                              id="btnLay"
                              class="ask  btn-lay mobile-hide bg-trans lh22"
                              side="lay"
                            ></a>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
              {data?.map((res) => {
                const odds_back =
                  activeInner == "toss"
                    ? backOdds
                        ?.filter((todd) => todd.ri == res.SelectionId)
                        ?.sort((a, b) => {
                          return a - b;
                        })
                    : activeInner == "bookmaker"
                    ? backOdds
                        ?.filter((todd) => todd?.runnerName == res?.RunnerName)
                        ?.sort((a, b) => {
                          return a - b;
                        })
                    : backOdds?.filter((todd) => todd.ri === res?.SelectionId);
                const odds_lay =
                  activeInner == "toss"
                    ? []
                    : activeInner == "bookmaker"
                    ? layOdds
                        ?.filter((todd) => todd?.runnerName == res?.RunnerName)
                        ?.sort((a, b) => {
                          return a - b;
                        })
                    : layOdds?.filter((todd) => todd.ri === res?.SelectionId);
                return (
                  <tr style={{ background: "white" }}>
                    <td class={type == "Toss" ? "w-90" : "w-55"}>
                      <span class="in-play-title">{res?.RunnerName}</span>
                      <div class="d-inline-block runnerid float-right"></div>
                    </td>
                    {type == "Toss" ? (
                      <td class="w-45 text-center">
                        <div class="">
                          <div class="widt50fleft widt50fleft-toss">
                            <div class="bid bid-toss">
                              <span class="bid-price lhightbook">
                                {odds_back?.length > 0 ? odds_back[0]?.bv : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td class="w-45 text-center">
                        <div class="">
                          <div class="widt50fleft">
                            {width > 767 && (
                              <>
                                <div class="bid back-light-bg1 mobile-hide">
                                  <span class="bid-price">
                                    {odds_back[2]?.rt || "-"}
                                  </span>
                                  {activeInner == "matchOdds" && (
                                    <span class="bid-price-small">
                                      {odds_back[2]?.bv || "-"}
                                    </span>
                                  )}
                                </div>
                                <div class="bid back-light-bg mobile-hide">
                                  <span class="bid-price">
                                    {odds_back[1]?.rt || "-"}
                                  </span>
                                  {activeInner == "matchOdds" && (
                                    <span class="bid-price-small">
                                      {odds_back[1]?.bv || "-"}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}
                            <div class="bid">
                              <span class="bid-price">
                                {odds_back[0]?.rt || "-"}
                              </span>
                              {activeInner == "matchOdds" && (
                                <span class="bid-price-small">
                                  {odds_back[0]?.bv || "-"}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="widt50fleft">
                            <div class="ask marg1">
                              <span class="bid-price">
                                {odds_lay[0]?.rt || "-"}
                              </span>
                              {activeInner == "matchOdds" && (
                                <span class="bid-price-small">
                                  {odds_lay[0]?.bv || "-"}
                                </span>
                              )}
                            </div>
                            {width > 767 && (
                              <>
                                {" "}
                                <div class="ask lay-light-bg mobile-hide">
                                  <span class="bid-price">
                                    {odds_lay[1]?.rt || "-"}
                                  </span>
                                  {activeInner == "matchOdds" && (
                                    <span class="bid-price-small">
                                      {odds_lay[1]?.bv || "-"}
                                    </span>
                                  )}
                                </div>
                                <div class="ask lay-light-bg1 mobile-hide">
                                  <span class="bid-price">
                                    {odds_lay[2]?.rt || "-"}
                                  </span>
                                  {activeInner == "matchOdds" && (
                                    <span class="bid-price-small">
                                      {odds_lay[2]?.bv || "-"}
                                    </span>
                                  )}
                                </div>{" "}
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Odds;
