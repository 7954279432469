import React from "react";
import obj from "../../utils/constants";
import { Link } from "react-router-dom";

export const FancyListDropdown = ({
  showMatch,
  data,
  selectData,
  fancyOdds,
  res,
  title,
}) => {
  return (
    showMatch && (
      <tr key={data[0]?.runnerName}>
        <td>{data[0]?.runnerName}</td>
        <td colSpan="5" className="px-0 gray-inner_table">
          <div className="d-flex justify-content-center align-items-center">
            <table className="selection-table" style={{ width: "900px" }}>
              <tbody className="">
                <tr className="">
                  <td
                    className="refer-bet border-0"
                    colSpan="2"
                    width="40%"
                  ></td>
                  <td className="border-0 p-0" width="15%">
                    <div
                      style={{ padding: "8px 10px" }}
                      className=" back-all-size"
                    >
                      <span>Yes</span>
                    </div>
                  </td>
                  <td className="border-0 p-0" width="15%">
                    <div
                      style={{ padding: "8px 10px" }}
                      className=" back-all-size"
                    >
                      <span>No</span>
                    </div>
                  </td>
                  <td
                    className="refer-book border-0"
                    colSpan="2"
                    width={"15%"}
                    id="layPercent"
                  ></td>
                </tr>
                <tr className="">
                  <td width="40%" className="border-start-0">
                    {data[0]?.fancyName}
                  </td>
                  <td className="border-l"></td>
                  <td
                    className="back-1 p-0 border-start-0"
                    style={{ cursor: "not-allowed" }}
                  >
                    <div className="light-blue rounded-0">
                      <strong>
                        {fancyOdds && fancyOdds.length > 0 && fancyOdds[0]?.rt
                          ? fancyOdds[0]?.rt
                          : "--"}
                      </strong>
                      <span className="d-block">
                        {fancyOdds && fancyOdds.length > 0 && fancyOdds[0]?.pt
                          ? fancyOdds[0]?.pt
                          : "--"}
                      </span>
                    </div>
                  </td>
                  <td className="lay-1 p-0" style={{ cursor: "not-allowed" }}>
                    <div className="dark-pink rounded-0">
                      <strong>
                        {fancyOdds && fancyOdds.length > 0 && fancyOdds[1]?.rt
                          ? fancyOdds[1]?.rt
                          : "--"}
                      </strong>
                      <span className="d-block">
                        {fancyOdds && fancyOdds.length > 0 && fancyOdds[1]?.pt
                          ? fancyOdds[1]?.pt
                          : "--"}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column align-items-start">
                      <span>Min / Max</span>
                      <span>1.00 / 100.00</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
          </div>
        </td>
      </tr>
    )
  );
};
