import React, { useEffect, useState } from "react";
import obj from "../../utils/helpers";
import { isArray } from "lodash";
import obj2 from "../../utils/constants";
const ReportTabel = ({ res, showDetailCasino }) => {
  return (
    <>
      <tr className="expand">
        <td
          colSpan="9"
          className="expand_wrap"
          style={{ background: "lightgrey" }}
        >
          <table
            style={{
              width: "100%",
              background: "lightgrey",
            }}
          >
            <tbody
              style={{
                background: "lightgrey",
              }}
            >
              {res?.child?.map((obj3) => {
                return (
                  <tr>
                    <td className="text-start">
                      <i
                        id={"icon_" + obj3?.userTypeId}
                        className={
                          obj3?.expand
                            ? "fas fa-minus-square pe-2"
                            : "fas fa-plus-square pe-2"
                        }
                        onClick={(e) => {
                          showDetailCasino(obj3, 2);
                        }}
                      ></i>
                      <a href={"#"} className="text-primary">
                        <span>{obj2?.user_status[obj3?.userType || ""]}</span>
                      </a>
                      {obj3?.username}
                    </td>
                    <td>
                      {" "}
                      <span
                        className={
                          Math.sign(obj3?.amount) !== -1
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        ({obj.currencyFormat(obj3?.amount)})
                      </span>
                    </td>
                    <td>
                      {" "}
                      <span
                        className={
                          Math.sign(obj3?.amount) == -1
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        ({obj.currencyFormat(obj3?.amount)})
                      </span>
                    </td>
                    <td>
                      {" "}
                      <span>({obj.currencyFormat(obj3?.commission)})</span>
                    </td>
                    <td>
                      {" "}
                      <span
                        className={
                          Math.sign(obj3?.amount) !== -1
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        ({obj.currencyFormat(obj3?.amount)})
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </td>
      </tr>
      {res?.child?.length > 0 && (
        <ChildList child={res?.child} showDetailCasino={showDetailCasino} />
      )}
    </>
  );
};

export default ReportTabel;

const ChildList = ({ child, showDetailCasino }) => {
  return child.map((item) => (
    <ReportTabel res={item} showDetailCasino={showDetailCasino} />
  ));
};
