import React, { useContext, useEffect, useState } from "react";
import { Container, Tabs, Tab, Table, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { apiGet, apiPut } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import AuthContext from "../context/AuthContext";
import obj from "../utils/helpers";
import obj2 from "../utils/constants";
import { isEmpty } from "lodash";
import UpdateDialogBox from "../components/UpdateDialogBox";
import { toast } from "wc-toast";
const Events = () => {
  const location = useLocation();
  const [commision, setComission] = useState(0);
  const [key, setKey] = useState("in_play");
  const [viewpage, setViewPage] = useState(0);
  const [filter, setFilter] = useState({
    eventType: 4,
    status: "in_play",
  });
  const [blockMatchStatus, setMatchBlockStatus] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [sportsListingUpdateId, setSportsListingUpdateId] = useState({});
  const setMatchBlockStatusToggle = () =>
    setMatchBlockStatus(!blockMatchStatus);
  const [data, setData] = useState([]);
  const getData = async (obj) => {
    let path = apiPath.matchFilterList;
    const finalFilter = !isEmpty(obj) ? obj : filter;
    finalFilter.hasMatchOdds = true;
    const { status, data: response_users } = await apiGet(path, finalFilter);
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };

  const handlePageClick = (event) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
    setViewPage(event.selected);
  };

  useEffect(() => {
    getData();
  }, []);

  const updateMatchStatusSports = async () => {
    setLoader(true);
    try {
      const { status, data: response_users } = await apiPut(
        apiPath.updateSportsListingStatus + `/${sportsListingUpdateId?.id}`,
        { status: sportsListingUpdateId?.status }
      );
      if (status === 200) {
        if (response_users.success) {
          getData();
          setLoader(false);
          toast.success(response_users.message);
          setMatchBlockStatusToggle();
          setSportsListingUpdateId({});
        } else {
          toast.error(response_users.message);
          setLoader(false);
          setMatchBlockStatusToggle();
          setSportsListingUpdateId({});
        }
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.response.data.message);
      setMatchBlockStatusToggle();
      setSportsListingUpdateId({});
    }
  };

  const updateMatchMatchOddsSports = async () => {
    setLoader(true);
    try {
      const { status, data: response_users } = await apiPut(
        apiPath.updateMatchOddsListingStatus + `/${sportsListingUpdateId?.id}`,
        {
          status: sportsListingUpdateId?.status,
          selectionId: sportsListingUpdateId?.SelectionId,
        }
      );
      if (status === 200) {
        if (response_users.success) {
          getData();
          toast.success(response_users.message);
          setMatchBlockStatusToggle();
          setLoader(false);
          setSportsListingUpdateId({});
        } else {
          toast.error(response_users.message);
          setLoader(false);
          setMatchBlockStatusToggle();
          setSportsListingUpdateId({});
        }
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.response.data.message);
      setMatchBlockStatusToggle();
      setSportsListingUpdateId({});
    }
  };
  return (
    <div>
    <section className="py-4 main-inner-outer">
      <Container fluid>
        <div className="right_side">
          <div className="inner-wrapper">
            <div className="common-tab">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  getData({
                    eventType: filter?.eventType,
                    status: k,
                  });
                  setFilter((prev) => {
                    return { ...prev, status: k };
                  });
                }}
              >
                <Tab eventKey="in_play" title="InPlay">
                  {key === "in_play" && (
                    <BettingProfitLossTab
                      url={location.pathname.split("/")[1]}
                      title={key}
                      setFilter={setFilter}
                      filter={filter}
                      data={data}
                      getData={getData}
                      viewpage={viewpage}
                      handlePageClick={handlePageClick}
                      comission={commision}
                      setSportsListingUpdateId={setSportsListingUpdateId}
                      setMatchBlockStatusToggle={setMatchBlockStatusToggle}
                    />
                  )}
                </Tab>
                <Tab eventKey="active" title="Upcomming">
                  {key === "active" && (
                    <BettingProfitLossTab
                      url={location.pathname.split("/")[1]}
                      title={key}
                      setFilter={setFilter}
                      filter={filter}
                      data={data}
                      getData={getData}
                      viewpage={viewpage}
                      handlePageClick={handlePageClick}
                      comission={commision}
                      setSportsListingUpdateId={setSportsListingUpdateId}
                      setMatchBlockStatusToggle={setMatchBlockStatusToggle}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>

      <UpdateDialogBox
        open={blockMatchStatus}
        onClose={setMatchBlockStatusToggle}
        onSubmit={
          sportsListingUpdateId?.type == "match"
            ? updateMatchStatusSports
            : updateMatchMatchOddsSports
        }
        isLoader={isLoader}
        headerTitle={"Update Status"}
        title={
          sportsListingUpdateId?.type == "match"
            ? `You Want to ${sportsListingUpdateId?.status} This Match?`
            : `You Want to ${sportsListingUpdateId?.status} Match Odds?`
        }
      />
    </section></div>
  );
};

export default Events;

const BettingProfitLossTab = ({
  filter,
  setFilter,
  title,
  data,
  getData,
  setSportsListingUpdateId,
  setMatchBlockStatusToggle,
}) => {
  let { user } = useContext(AuthContext);

  const [getProperty, setProperty] = useState("none");
  const showDetail = (event, id) => {
    const detailDiv = document.getElementById(id);
    if (detailDiv) {
      if (detailDiv?.style?.display === "none") {
        detailDiv.style.setProperty("display", "contents");
        event.target.className = "fas fa-minus-square pe-2";
      } else {
        detailDiv.style.setProperty("display", "none");
        event.target.className = "fas fa-plus-square pe-2";
      }
    }
  };

  return (
    <div className="common-container">
      <Form className="bet_status bet-list-live">
        <div className="d-lg-flex">
          <div key={`inline-radio`} className="mb-1">
            <ul className="list-unstyled mb-0 d-flex">
              {obj2.betCheckData2.map((type) => {
                return (
                  <li>
                    <Form.Check
                      inline
                      label={type.value}
                      name={type.label}
                      type="radio"
                      checked={type.label == filter.eventType ? true : false}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          eventType: e.target.name,
                        });
                        getData({
                          ...filter,
                          eventType: e.target.name,
                        });
                      }}
                      id={`inline-radio-1`}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Form>
      <div className="account-table batting-table profit_loss_table">
        <div className="responsive">
          <Table>
            <thead>
              <tr>
                <th scope="col">Market Info</th>
                <th scope="col">Status</th>
                {title == "in_play" && <th scope="col">TV</th>}
                <th scope="col">Time</th>
                {title == "in_play" && <th scope="col">User Online</th>}
                {title == "in_play" && <th scope="col">No. of bets</th>}
              </tr>
            </thead>
            {data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <>
                    <tr
                      id="summary0"
                      style={{ display: "table-row" }}
                      key={index + 1}
                    >
                      <td className="text-start">
                        {item?.jsonData?.length > 0 && (
                          <i
                            id={"icon_" + item?._id}
                            className="fas fa-plus-square"
                            onClick={(e) => showDetail(e, item?._id)}
                          ></i>
                        )}
                        {item?.eventName}
                        <span className="angle_unicode">▸</span>
                        <strong>[{item?.seriesName}]</strong>
                      </td>
                      <td>
                        <Form.Check
                          type="switch"
                          checked={true}
                          onClick={function (e) {
                            setSportsListingUpdateId({
                              id: item?._id,
                              status: "inActive",
                              type: "match",
                            });
                            setMatchBlockStatusToggle();
                          }}
                        />
                      </td>
                      {title == "in_play" && <td></td>}
                      <td id="settledDate">
                        {" "}
                        {obj.dateFormat(item.eventDateTime, user.timeZone)}
                      </td>
                      {title == "in_play" && <td>0</td>}
                      {title == "in_play" && <td>0</td>}
                    </tr>

                    <tr
                      className="expand"
                      id={item?._id}
                      style={{ display: getProperty }}
                    >
                      <td colSpan="9" className="expand_wrap">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <th>Match Odds</th>
                              <th></th>
                            </tr>
                            {item?.jsonData?.map((res) => {
                              return (
                                <tr>
                                  <th
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {res?.RunnerName}
                                  </th>
                                  <th>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span>Match Odds </span>
                                      <Form.Check
                                        type="switch"
                                        checked={
                                          res?.status === 3 ? false : true
                                        }
                                        onClick={function (e) {
                                          setSportsListingUpdateId({
                                            id: item?._id,
                                            status: "inActive",
                                            type: "matchOdds",
                                            SelectionId: res?.SelectionId,
                                          });
                                          setMatchBlockStatusToggle();
                                        }}
                                      />
                                    </div>
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <span>You have no bets in this time period.</span>
                </td>
              </tr>
            )}
          </Table>
          {/* <div className="bottom-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              forcePage={viewpage}
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              activeClassName="p-1"
              activeLinkClassName="pagintion-li"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
